import Env from "./envModule";
import User from "./userModule";
import ExhibitorOrderModule from "@/store/exhibitorOrder";

export class StoreConfig {
	static async bootstrap() {
		await Promise.all([
			Env.bootstrap(),
			User.bootstrap(),
			ExhibitorOrderModule.bootstrap()
		]);
	}
}