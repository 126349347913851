<template>
    <store-page>
        <v-container v-if="isInitialized">
            <v-row justify="center">
                <v-col md="6">
                    <v-card v-if="!emailSent">
                        <v-card-title>
                            <vops-header :label="$t($tl.reset.requestPasswordReset)"/>
                        </v-card-title>
                        <v-card-text>
                            <v-divider/>
                            <vops-form @submit="submit">
                                <v-container>
                                    <v-text-field v-model="email" :label="$t($tl.email)"
                                                  :rules="[$rules.required, $rules.email]" v-focus/>
                                    <div class="mb-4 text-center error--text" v-if="invalidEmail">
                                        {{$t($tl.reset.noUserFoundForThisEmail)}}
                                    </div>
                                    <v-btn type="submit" color="primary" :loading="isSaving" class="mt-2" block>
                                        {{ $t($tl.sendEmail) }}
                                    </v-btn>
                                    <div class="mt-4 text-center">
                                        <router-link :to="{ name: 'login' }">{{ $t($tl.backToLogin) }}</router-link>
                                    </div>
                                </v-container>
                            </vops-form>
                        </v-card-text>
                    </v-card>
                    <v-card v-else>
                        <v-card-text>
                            <v-container>
                                <div class="text-center">
                                    <v-icon color="success" size="96">check</v-icon>
                                    <div class="mt-2 font-weight-bold">{{ $t($tl.emailSentToValue, { value: this.email }) }}</div>
                                    <div class="mt-4">{{ $t($tl.reset.clickLinkToReset) }}</div>
                                    <div class="mt-4">{{ $t($tl.checkJunk) }}</div>
                                    <div class="mt-4 text-center">
                                        <router-link :to="{ name: 'login' }">{{ $t($tl.backToLogin) }}</router-link>
                                    </div>
                                </div>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </store-page>
</template>

<script lang="ts">
    import {Component, Vue} from "vue-property-decorator";
    import StorePage from "@/components/common/StorePage.vue";
    import {UserService} from "@/services/generated";
    import StoreConfigModule from "@/store/storeConfigModule";

    @Component({
        components: {StorePage}
    })
    export default class UserForgotPassword extends Vue {
        email: string = null;
        isSaving: boolean = false;
        emailSent: boolean = false;
        invalidEmail: boolean = false;
        isInitialized: boolean = false;

        async created() {
            await StoreConfigModule.clearConfig();
            this.isInitialized = true;
        }

        async submit() {
            if (this.isSaving)
                return;

            try {
                this.isSaving = true;

                await UserService.requestPasswordReset({email: this.email});

                this.isSaving = false;
                this.emailSent = true;
            } catch (e) {
                this.isSaving = false;
                this.invalidEmail = true;
            }
        }
    }
</script>