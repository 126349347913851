<template>
		<vops-form @submit="submit" v-if="isInitialized">
			<vops-slider-pane  @cancel="close()">
				<template #label>
					<v-icon color="primary" x-large class="mr-2">person_pin</v-icon> {{ $tc($tl.newAccount) }}
				</template>
					<v-container  v-if="!isAwaitingVerification">
						<v-text-field v-model="name" :label="$t($tl.name)" :rules="[$rules.required]" v-focus/>
						<v-text-field v-model="email" :label="$t($tl.email)"
									  :rules="[$rules.required, $rules.email, validEmailRule]"
									  @input="validateEmail"/>
						<v-text-field v-model="phone" :label="$t($tl.phone)" :rules="[$rules.required]"/>
						<v-text-field v-model="organization" :label="$t($tl.organization)"
									  :rules="[$rules.required]"/>
						<form-address v-model="billingAddress"/>
					</v-container>
					<v-container v-else>
						<div class="text-center">
							<v-icon color="success" size="96">check</v-icon>
							<div class="mt-2 font-weight-bold">{{ $t($tl.emailSentToValue, { value: this.email }) }}</div>
							<div class="mt-4">{{ $t($tl.registration.clickLinkToActivate) }}</div>
							<div class="mt-4">{{ $t($tl.checkJunk) }}</div>
						</div>					
					</v-container>
					<template #footer>
						<div v-if="!isAwaitingVerification" class="sliderPane-footer-content px-6 py-3">
							<v-btn class="sliderPane-footer-content" color="primary" type="submit" >
								{{ $t($tl.registration.register) }}
							</v-btn>
							<v-btn class="sliderPane-footer-content ml-4" @click="close">
								{{ $tc($tl.cancel) }}
							</v-btn>
						</div>
						<div v-else class="sliderPane-footer-content px-6 py-3">
							<v-btn class="sliderPane-footer-content" color="primary" @click="gotoLogin" >
								{{ $t($tl.login) }}
							</v-btn>
							<v-btn class="sliderPane-footer-content ml-4" @click="close">
								{{ $tc($tl.close) }}
							</v-btn>
						</div>
					</template>
			</vops-slider-pane>
		</vops-form>
</template>


<script lang="ts">
import {Emit, Component, Vue, Watch} from "vue-property-decorator";
import FormAddress, {Address} from "@/components/common/FormAddress.vue";
import StoreConfigModule from "@/store/storeConfigModule";
import {CreateUserModel} from "@/models/generated";
import {UserService} from "@/services/generated";
import {Debounce} from "vue-debounce-decorator";
@Component({
	components: {FormAddress}
})
export default class  extends Vue {
	isInitialized: boolean = false;
	name: string = null;
	email: string = null;
	phone: string = null;
	organization: string = null;
	billingAddress: Address = {
		street: null,
		unit: null,
		locality: null,
		region: null,
		postalCode: null,
		country: null
	};
	
	@Emit()
	close() {};

	@Emit() 
	gotoLogin(){
		return this.email;
	};
	
	isEmailValid: boolean = false;
	isAwaitingVerification: boolean = false;
	isSaving: boolean = false;

	async created() {
		this.isInitialized = true;
	}

	async submit() {
		if (this.isSaving)
			return;

		try {
			this.isSaving = true;

			const model: CreateUserModel = {
				name: this.name,
				email: this.email,
				phone: this.phone,
				organization: this.organization,
				billingAddress: this.billingAddress
			};
			await UserService.createUser(model);

			this.isSaving = false;
			this.isAwaitingVerification = true;
		} catch (e) {
			this.isSaving = false;
		}
	}

	@Debounce(150)
	async validateEmail() {
		if (!this.email) {
			this.isEmailValid = true;
			return;
		}
		this.isEmailValid = await UserService.isValidEmail(this.email);
	}

	get validEmailRule() {
		return this.isEmailValid || this.$tc(this.$tl.emailAlreadyExists);
	}	
}
</script>