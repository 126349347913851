<template>
	<store-page>
		<v-container>
			<v-row justify="center">
				<v-col md="6">
					<v-card>
						<v-card-text>
							<v-container>{{ $t($tl.pageNotFound) }}</v-container>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</store-page>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import StorePage from "@/components/common/StorePage.vue";
@Component({
	components: {StorePage}
})
export default class NotFound extends Vue {
}
</script>