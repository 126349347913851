<template>
    <v-app v-bind="$attrs">
            <store-toolbar v-if="$vuetify.breakpoint.smAndUp"/>
			<store-toolbar-mobile v-else />
			<router-view/>
			<vops-slider-drawer/>
			<store-sliders/>
			<axios-message-snackbar/>
	        <!--<store-footer/>-->
    </v-app>
</template>

<script lang="ts">
    import {Component, Vue} from "vue-property-decorator";
    import AxiosMessageSnackbar from './components/common/AxiosMessageSnackbar.vue';
    import StoreFooter from "@/components/common/StoreFooter.vue";
    import StoreToolbar from "@/components/common/StoreToolbar.vue";
	import VopsSliderDrawer from '@eventbooking/vops-ui/components/SliderDrawer.vue'
	import StoreSliders from "@/components/common/StoreSliders.vue";
	import StoreToolbarMobile from "@/components/common/StoreToolbarMobile.vue";
	
    @Component({
        components: {StoreToolbarMobile, StoreSliders, StoreToolbar, StoreFooter, AxiosMessageSnackbar, VopsSliderDrawer}
    })
    export default class App extends Vue {
    }
</script>

<style lang="scss">
    .app-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
</style>