<template>
	<vops-form @submit="submit()" v-if="isInitialized">
		<vops-slider-pane :label="$tc($tl.editItem)" :is-saving="isSaving" :save-label="$tc($tl.updateItem)" @cancel="cancel()">
			
			<v-list>
				<v-list-item class="storeItemCategoryInstruction" outlined dense v-if="storeItem.categoryExhibitionInstruction">
					<v-list-item-icon>
						<v-icon color="primary lighten1">mdi-information-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content class="text-pre-wrap">{{ storeItem.categoryExhibitionInstruction }}</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-subtitle>{{ $tc($tl.name) }}</v-list-item-subtitle>
						<v-list-item-title>{{ storeItem.name }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			
				<v-list-item>
					<v-list-item-content>
						<v-list-item-subtitle>{{ $tc($tl.description) }}</v-list-item-subtitle>
						<div>{{ storeItem.description }}</div>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-subtitle>{{ $tc($tl.orders.price) }}</v-list-item-subtitle>
						<v-list-item-title>{{storeItem.price | money(this.currency)}} {{unitNames}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>
							<vops-number-input :label="$tc($tl.orders.quantity)" required v-model="quantity" min="1"/>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-if="storeItem.isTimeBound">
					<v-list-item-content>
						<v-list-item-title>
							<vops-number-input class="text-capitalize" :label="timeQuantityLabel" v-model="timeQuantity" required min="1"/>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>
							<v-text-field :label="$tc($tl.orders.instruction)" v-model="instruction" />
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</vops-slider-pane>
	</vops-form>
</template>


<script lang="ts">
import {Component, Vue, Emit, Watch} from "vue-property-decorator";
import * as ESH from "@/services/exhibitorStoreHelper";
import ExhibitorStoreModule from "@/store/exhibitorStoreModule";
import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";

@Component({
	components: {}
})
export default class ExhibitorStoreEditCartItem extends Vue {
	isInitialized: boolean = false;
	isSaving: boolean = false;
	storeItemId: string = null;
	storeItem: ESH.StoreItem = null;
	currency:string = null;
	quantity:number = 1;
	timeQuantity: number = 1;
	instruction:string = null;

	unsubscribe = () => {};

	@Emit()
	submit(){
		ExhibitorStoreHelper.updateCart({id: this.storeItem.id, quantity: parseInt("" + this.quantity), instruction: this.instruction, timeQuantity: this.timeQuantity, isInCart:true});
	};

	@Emit()
	cancel(){}

	get storeSettings() {
		return ExhibitorStoreModule.storeSettings;
	}

	async created() {
		this.unsubscribe = ExhibitorStoreHelper.subscribeToStore(this.storeChanged);
		this.loadItem();
		this.isInitialized = true;
	}

	get unitNames() {
		const timeLabel = ExhibitorStoreHelper.getTimeQuantityLabel(this.storeItem.timeUnit,1);
		return  this.storeItem.isTimeBound ? `${this.storeItem.unitName} per ${timeLabel}`:`${this.storeItem.unitName}`;
	}

	get timeQuantityLabel() {
		return ExhibitorStoreHelper.getTimeQuantityLabel(this.storeItem.timeUnit, this.timeQuantity);
	}

	storeChanged(action) {
		if(action.type === "StoreConfigModule/subscriberEvent" && action.payload==="Edit_Item_In_Cart")
			this.loadItem();
	}

	loadItem() {
		this.currency = this.storeSettings.currency;
		this.storeItemId = ExhibitorStoreModule.currentItemId;
		this.storeItem = ExhibitorStoreModule.storeItems.find(item => item.id === this.storeItemId);
		this.quantity = this.storeItem.quantity > 0 ? this.storeItem.quantity : 1;
		this.timeQuantity = ExhibitorStoreHelper.getTimeQuantity(this.storeItem.isTimeBound,  this.storeItem.timeQuantity);
		this.instruction = this.storeItem.instruction;
	}
}
</script>
<style lang="scss">
.storeItemCategoryInstruction {
  margin-left: 10px;
  margin-right: 10px;
  
  border:1px solid var(--v-grey-lighten1);
  border-radius: 15px;
  
  .v-list-item__icon:first-child {
	margin-right: 8px;
 }
  .v-list-item__content {
	font-weight: 500;
  }
}
</style>