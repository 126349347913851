import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/config/plugins/store';
import {CultureModel, StoreConfigModel} from "@/models/generated";
import {CultureService, StoreConfigService} from "@/services/generated";
import MomentConfig from "@/config/moment/momentConfig";
import LocalizationConfig from "@/config/i18n/localizationConfig";
import FiltersConfig from "@/config/filters/filtersConfig";

@Module({ dynamic: true, store: store, namespaced: true, name: "StoreConfigModule" })
class StoreConfig extends VuexModule {
    tenantId: string = null;
    storeConfig: StoreConfigModel = null;
    fallbackCulture: CultureModel = null;
	sliders = {
		addToCart : false,
		editCartItem: false,
		login: false,
		forgotPassword: false,
		resetEmailSent: false,
		registerNewAccount: false
	}

	@Action 
	subscriberEvent(event:string) {}
	
	@Action
	setSliderOpen(slider:string) {
		this.onSetSliderOpen(slider);
		this.subscriberEvent(SubscriberAction.sliders_updated)
	}

	@Action
	setSliderClosed(slider:string) {
		this.onSetSliderClosed(slider);
		this.subscriberEvent(SubscriberAction.sliders_updated)
	}

	@Mutation
	onSetSliderClosed(slider) {
		this.sliders[slider] = false;
	}

	@Mutation
	onSetSliderOpen(slider:string) {
		this.sliders[slider] = true;
	}
	
    @Mutation
    private setConfig(payload: SetConfigPayload) {
        this.tenantId = payload.tenantId;
        this.storeConfig = payload.storeConfig;
    }

    @Mutation
    private setFallbackCulture(fallbackCulture: CultureModel) {
        this.fallbackCulture = fallbackCulture;
    }

    @Action
    async updateConfig(tenantId: string) {
		if (tenantId) {
			const storeConfig = await StoreConfigService.getConfig(tenantId);
			this.setConfig({tenantId, storeConfig});

			MomentConfig.bootstrap(this.storeConfig.languageTag, this.storeConfig.languageOverride);
			await LocalizationConfig.bootstrap(this.storeConfig.languageOverride);
			await FiltersConfig.bootstrap(this.storeConfig.languageTag, this.storeConfig.currencyOptions.precision);
		} else {
			await this.clearConfig();
		}
    }

    @Action
    async clearConfig() {
        this.setConfig({tenantId: null, storeConfig: null});

        if (this.fallbackCulture == null) {
            const fallbackCulture = await CultureService.getCulture();
            this.setFallbackCulture(fallbackCulture);
        }

        MomentConfig.bootstrap(this.fallbackCulture.languageTag, this.fallbackCulture.languageOverride);
        await LocalizationConfig.bootstrap(this.fallbackCulture.languageOverride);
        await FiltersConfig.bootstrap(this.fallbackCulture.languageOverride, this.fallbackCulture.currencyOptions.precision);
    }
}

interface SetConfigPayload {
    tenantId: string;
    storeConfig: StoreConfigModel;
}

export enum SubscriberAction {
	sliders_updated = "Sliders_Updated",
	store_items_updated = "Store_Items_Updated",
	add_item_to_cart = "Add_Item_To_Cart",
	edit_item_in_cart = "Edit_Item_In_Cart",
	cart_total_updated = "Cart_Total_Updated",
	search_cleared = "Search_Cleared",
	search_updated = "Search_Updated",
	toggle_navigation_menu = "Toggle_Navigation"
}

export enum SliderNames {
	addToCart = "addToCart",
	editCartItem= "editCartItem",
	login= "login",
	forgotPassword= "forgotPassword",
	resetEmailSent= "resetEmailSent",
	registerNewAccount = "registerNewAccount"
}

const StoreConfigModule = getModule(StoreConfig);
export default StoreConfigModule;