<template>
    <store-page>
        <v-container v-if="isInitialized">
            <v-row justify="center">
                <v-col md="6">
                    <v-card>
                        <v-card-title>
                            <vops-header :label="$t($tl.orderHistory)"/>
                        </v-card-title>
                        <v-card-text>
                            <v-divider/>
                            <v-list v-if="orders.length > 0">
                                <v-list-item v-for="order in orders" :key="order.id" @click="selectOrder(order)">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ order.exhibition.name }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ order.exhibition.venueNames.join(', ') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            {{ order.grandTotal | money(order.currency) }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="order.isPaid">
                                            {{ $t($tl.orders.paidOn) }} {{ order.paidOn | moment('L LT') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="order.isPaid">
                                            #{{ order.uniqueId }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="order.isPending">
                                            {{ $t($tl.orders.pending) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-container class="font-italic" v-else>{{ $tc($tl.orders.order, 0) }}</v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </store-page>
</template>

<script lang="ts">
    import {Component, Vue} from "vue-property-decorator";
    import UserModule from "@/store/userModule";
    import {OrderModel} from "@/models/generated";
    import {OrdersService} from "@/services/generated";
    import StorePage from "@/components/common/StorePage.vue";
    import ExhibitorOrderModule from "@/store/exhibitorOrder";
    import StoreConfigModule from "@/store/storeConfigModule";
	import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";
	import ExhibitorStoreModule from "@/store/exhibitorStoreModule";

    @Component({
        components: {StorePage}
    })
    export default class User extends Vue {
        orders: OrderModel[] = [];
        isInitialized: boolean = false;

        get currentUser() { return UserModule.user; }

        async created() {
            if (this.currentUser == null) {
                await this.$router.push({name: 'login'});
                return;
            }

            await StoreConfigModule.clearConfig();
            this.orders = await OrdersService.getByCurrentUser();
            this.orders.sort((a, b) => b.createdOn.localeCompare(a.createdOn));
            this.isInitialized = true;
        }

        async selectOrder(order: OrderModel) {
            if (order.isPending) {
                await StoreConfigModule.updateConfig(order.tenantId);
				await ExhibitorStoreHelper.configureStore(order.tenantId, order.exhibition.exhibitionId, UserModule.user.id)
                await ExhibitorOrderModule.updateOrder({
                    id: order.id,
                    tenantId: order.tenantId,
                    currency: order.currency,
                    booth: order.booth,
                    exhibitionId: order.exhibition.exhibitionId,
                    priceScheduleId: order.priceScheduleId,
                    venueNames: order.exhibition.venueNames,
                    exhibitionName: order.exhibition.name,
					onsiteContact: order.onsiteContact,
                    attachments: order.attachments.map(x=> ({id: x.id, name: x.name})),
                    items: order.items.map(x => {
							const isTimeBound = x.timeUnit !== null;
							return	{
								id: x.itemId,
								name: x.name,
								unit: x.unitName,
								instruction: x.instruction,
								quantity: x.quantity,
								timeQuantity: ExhibitorStoreHelper.getTimeQuantity(isTimeBound, x.timeQuantity),
								categoryId: x.categoryId,
								categoryName: x.categoryName,
								timeUnit: x.timeUnit,
								isTimeBound: isTimeBound
							}}
					)
                });
                await this.$router.push({ name: 'orderPreview', params : {tenantId: ExhibitorStoreModule.storeSettings.tenantId, exhibitionId: ExhibitorStoreModule.storeSettings.exhibitionId} });
            } else {
                await this.$router.push({ name: 'orderView', params: {tenantId: ExhibitorStoreModule.storeSettings.tenantId, exhibitionId: ExhibitorStoreModule.storeSettings.exhibitionId, orderId: order.id } });
            }
        }
    }
</script>