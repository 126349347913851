<template>
	<v-snackbar v-if="isShowingRefresh"
				:value="isShowingRefresh"
				color="info"
				:timeout="0"
				bottom>
		<span>{{ $t($tl.applicationUpdated) }}</span>
		<v-btn color="white" text @click="refreshPage">{{ $t($tl.refresh) }}</v-btn>
	</v-snackbar>

	<v-snackbar v-else-if="isShowingError"
				:value="isShowingError"
				@input="clearError"
				color="error"
				:timeout="12000"
				bottom>
		<span>{{ axiosError }}</span>
		<v-btn color="white" text @click="clearError">{{ $t($tl.close) }}</v-btn>
	</v-snackbar>
</template>

<script lang="ts">
	import {Component, Vue, Watch} from "vue-property-decorator";
	import AxiosMessageModule from '@/store/axiosMessageModule';

	@Component
	export default class AxiosMessageSnackbar extends Vue {
		isShowingError: boolean = false;
		isShowingRefresh: boolean = false;

		get axiosError() { return AxiosMessageModule.error; }
		get axiosRefresh() { return AxiosMessageModule.refresh; }

		@Watch("axiosError")
		axiosErrorChanged() {
			this.isShowingError = this.axiosError != null;
		}

		@Watch("axiosRefresh")
		axiosRefreshChanged() {
			this.isShowingRefresh = this.axiosRefresh;
		}

		clearError() {
			this.isShowingError = false;
			AxiosMessageModule.clearError();
		}

		refreshPage() {
			window.location.reload();
		}
	}
</script>