import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import { EnvironmentModel } from '@/models/generated';
import { EnvironmentService } from '@/services/generated';
import store from '@/config/plugins/store';

@Module({ dynamic: true, store: store, namespaced: true, name: "EnvModule" })
class Env extends VuexModule {
	environment: EnvironmentModel = null;

	@Mutation
	private onBootstrap(environment: EnvironmentModel) {
		this.environment = environment;
	}

	@Action
	async bootstrap() {
		const environment = await EnvironmentService.get();
		this.onBootstrap(environment);
	}
}

const EnvModule = getModule(Env);
export default EnvModule;