<template>
    <store-page>
        <v-container v-if="isInitialized">
            <v-row justify="center">
                <v-col md="6">
                    <v-card >
                        <v-card-title>
							{{$tc($tl.exhibition, 2)}}
                        </v-card-title>
            			<v-card-text v-if="storeConfig.isStoreActive">
							<v-divider/>
						    <v-list>
                                <v-list-item v-for="exhibition in exhibitions" :key="exhibition.id"
                                             :to="{ name: 'exhibitorStoreCategory', params: { tenantId: tenantId, exhibitionId: exhibition.id }}">
                                    <v-list-item-content>
                                        <v-list-item-title class="blue--text text--lighten-2">
                                            {{ exhibition.eventName }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ exhibition.startDate | dateRange(exhibition.endDate, { allDay: true }) }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            {{ exhibition.venueNames.join(", ") }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="exhibitions.length === 0">
                                    <v-list-item-content>
                                        <v-list-item-title><h3 class="text-center">{{ $t($tl.noExhibitionsAvailable) }}</h3></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                        <v-card-text v-else>
                            <v-divider/>
                            <v-container>{{ $t($tl.storeNotActive) }}</v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </store-page>
</template>

<script lang="ts">
    import {Component, Vue} from "vue-property-decorator";
    import StorePage from "@/components/common/StorePage.vue";
    import {ExhibitionWebModel, StoreConfigModel} from "@/models/generated";
    import {ExhibitionsService, StoreConfigService} from "@/services/generated";
    import StoreConfigModule from "@/store/storeConfigModule";
	import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";

    @Component({
        components: {StorePage}
    })
    export default class Exhibitions extends Vue {
        tenantId: string = null;
        exhibitions: ExhibitionWebModel[] = null;
        isInitialized: boolean = false;

        get storeConfig() {
            return StoreConfigModule.storeConfig;
        }

        async created() {
            const {tenantId, venueId} = this.$route.params;
            this.tenantId = tenantId;

			ExhibitorStoreHelper.resetStore();
			
            await StoreConfigModule.updateConfig(this.tenantId);

            this.exhibitions = this.storeConfig.isStoreActive ?
                await ExhibitionsService.getExhibitionsAvailableForOrdering(tenantId, venueId) : [];
            this.exhibitions.sort((a, b) => a.startDate.localeCompare(b.startDate));

            this.isInitialized = true;
        }
    }
</script>