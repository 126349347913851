<template>
<v-toolbar class="exhibitorSearchBar" light flat >
	<v-app-bar-nav-icon v-if="isBreakPoint" @click.stop="toggleNav" />
		<v-app-bar-nav-icon v-else @click.stop="toggleNav" @click="show = !show">
			<v-icon class="material-symbols-outlined">
				{{ !show ? 'left_panel_close' : 'left_panel_open' }}
			</v-icon>
		</v-app-bar-nav-icon>
		<v-spacer/>
		<v-text-field outlined
				placeholder=" Search All Items"
				prepend-inner-icon="mdi-magnify"
				single-line
				rounded dense 
				hide-details  
				clearable
				v-model="searchText"
				@input="searchChanged"
		/>		
		<v-spacer/>
	<template v-if="userLoggedIn">
		<template v-if="showCheckout">
			<v-btn v-if="showCheckout" class="mr-2 ml-2" small color="primary lighten-1" @click="checkOut">{{ $tc($tl.checkout) }}</v-btn>
			{{totalPurchaseAmount | money(currency)}}
		</template>

		<v-btn v-else class="cartBtn" text @click="goToCart" title="Cart total does not include taxes">
			<v-badge v-if="cartItemCount > 0"
					 color="orange"
					 :content="cartItemCount"
					 overlap
					 class="mr-2 ml-2"
			>
				<v-icon color="primary">mdi-cart-outline</v-icon>
			</v-badge>
			<v-icon v-else class="mr-4" color="primary">mdi-cart-outline</v-icon>
			{{totalPurchaseAmount | money(currency)}}
		</v-btn>
	</template>
</v-toolbar>
</template>

<script lang="ts">

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Debounce} from "vue-debounce-decorator";
import exhibitorStoreModule from "@/store/exhibitorStoreModule";
import ExhibitorStoreModule from "@/store/exhibitorStoreModule";
import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";
import StoreConfigModule, {SliderNames, SubscriberAction} from "@/store/storeConfigModule";
import ExhibitorOrderModule from "@/store/exhibitorOrder";
import UserModule from "@/store/userModule";


@Component({
	components: {}
})

export default class ExhibitorStoreSearchBar  extends Vue {
	@Prop() label: string;
	isBreakPoint: boolean = false;
	show: boolean = false;
	searchText:string = "";
	tenantId: string="";
	exhibitionId: string="";
	unsubscribe = null;
	currency:string = null;

	

	toggleNav() {
		StoreConfigModule.subscriberEvent(SubscriberAction.toggle_navigation_menu);
	}

	mounted() {
		this.isBreakPoint = window.matchMedia('(max-width: 1264px)').matches;
		window.addEventListener('resize', this.onResize);
	}

	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
		this.unsubscribe();
	}

	onResize() {
		this.isBreakPoint = window.matchMedia('(max-width: 1264px)').matches
	}
	
	get storeSettings() {
		return ExhibitorStoreModule.storeSettings;
	}

	async created() {
		this.tenantId = this.storeSettings.tenantId;
		this.exhibitionId = this.storeSettings.exhibitionId
		this.currency = this.storeSettings.currency;
		this.unsubscribe = ExhibitorStoreHelper.subscribeToStore(this.subscriptionAction)
	}
	
	get totalPurchaseAmount() {
		return exhibitorStoreModule.totalPurchaseAmount;
	}
	
	get cartItemCount() {
		return exhibitorStoreModule.cartItemCount;
	}
	
	get showCheckout() {
		return this.$route.name === "exhibitorStoreCart" && this.cartItemCount > 0;
	}

	get userLoggedIn() {
		return UserModule.user;
	}
	
	subscriptionAction(action) {
		if (action.type !== "StoreConfigModule/subscriberEvent")
			return;

		if (action.payload === SubscriberAction.search_cleared)
			this.searchText = null;
	}
	
	@Debounce(300)
	searchChanged() {
		ExhibitorStoreHelper.setSearchText(this.searchText);
		
		if ((!this.searchText || this.searchText.length === 0) && this.$route.name ==="exhibitorStoreSearch")
		{
			this.$router.push({name: 'exhibitorStoreCategory', params:{tenantId: this.tenantId, exhibitorId:this.exhibitionId, categoryId: ExhibitorStoreModule.lastCategoryId}});
			return;
		}			
		
		if (this.$route.name !== "exhibitorStoreSearch")
			this.$router.push({name: 'exhibitorStoreSearch', params:{tenantId: this.tenantId, exhibitorId:this.exhibitionId}});
	}
	
	goToCart() {
		if (this.$route.name !== "exhibitorStoreCart")
			this.$router.push({name: 'exhibitorStoreCart', params:{tenantId: this.tenantId, exhibitorId:this.exhibitionId}});
	}

	async checkOut() {
		if (!UserModule.user) {
			StoreConfigModule.setSliderOpen(SliderNames.login);
			return;
		}
			
		const order = ExhibitorStoreHelper.generateStoreOrder();
		await ExhibitorOrderModule.updateOrder(order);
		await this.$router.push({name: 'orderPreview'});
	}
}
</script>
<style lang="scss">
	.exhibitorSearchBar {
	  position: -webkit-sticky;
	  position: sticky;
	  top:48px;
	  border-top: orange 1px solid !important;
	  z-index: 7;
	  height: 54px!important;
	  
	  .cartInfo {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 10px;
	 }
	}
	.mainAppBarMobileFixed + div  .exhibitorSearchBar {
		top:92px;
	  }
	
</style>