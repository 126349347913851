<template>
        <v-app-bar class="mainAppBar" fixed color="grey-darken-4" dense dark>
            <v-toolbar-title class="ml-0 pl-0" style="width:250px;padding:0px;">
				<v-btn @click="goToExhibitionList" elevation="0" left class="backToExList" v-if="!hideBackButton">
					<v-icon>mdi-arrow-left-circle</v-icon>
				</v-btn>
				<div class="exhibitionWrap">
					  <div class="exhibitionTitle">{{ $t($tl.exhibitorStore) }}</div>
					  <div v-if="isInExhibit" class="venueName" :title="exhibitionVenue.join(', ')" >{{ exhibitionVenue.join(", ") }}</div>
				</div>
			</v-toolbar-title>
			<template v-if="isInExhibit && this.exhibitionName">
				<v-divider class="exhibitNameSpace" vertical/>
				<span class="exhibitionName">{{exhibitionName}}</span>
				<span>&bull;</span>
				<span class="exhibitionDates">{{ eventDate }}</span>
			</template>
			<template v-if="currentUser">
                <v-spacer/>
                <v-menu offset-y>
                    <template #activator="{ on }">
                        <v-btn v-on="on" text>
                            {{ currentUser.name }}
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item :to="{ name: 'user', params: { tenantId: tenantId, exhibitionId:exhibitionId}}">
                            <v-list-item-content>
                                <v-list-item-title>{{ $t($tl.home) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="signOut">
                            <v-list-item-content>
                                <v-list-item-title>{{ $t($tl.signOut) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
			<template v-else>
				<v-spacer/>
				<v-btn text @click="goToLogin"><v-icon small class="mr-1">mdi-account</v-icon>{{ $tc($tl.login) }}</v-btn>
			</template>
        </v-app-bar>
</template>

<script lang="ts">
	import {Component, Watch, Vue, Prop, Emit} from "vue-property-decorator";
    import {AuthService} from "@/services/generated";
    import UserModule from "@/store/userModule";
    import ExhibitorOrderModule from "@/store/exhibitorOrder";
	import StoreConfigModule, {SliderNames, SubscriberAction} from "@/store/storeConfigModule";
	import ExhibitorStoreModule from "@/store/exhibitorStoreModule";
	import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";
	import moment from 'moment';
	
	@Component ({
		components: {}
	})
	
	export default class StoreToolbar extends Vue {
		routeName: string = null;
		tenantId: string = null; 
		exhibitionId: string = null;

		@Watch("$route.params.tenantId")
		@Watch("$route.params.exhibitorId")
		updateTenant() {
			this.tenantId = ExhibitorStoreModule.storeSettings.tenantId;
			this.exhibitionId = ExhibitorStoreModule.storeSettings.exhibitionId;
		}
		
		@Watch('$route')
		onRouteUpdated() {
			this.routeName = this.$router.currentRoute.name;
		}
		
		//The route name from "router.ts" and "ExhibitorRoutes.ts"
		get isInExhibit() {
		return [
				"exhibitorStoreCategory",
				"exhibitorStoreSearch",
				"exhibitorStoreCart",
				"orderPreview",
				"orderView",
				"orderPortalPay"
			].includes(this.$route.name);
		}
		
		get exhibitionName() {
			return ExhibitorStoreModule.storeSettings.eventName;		
		}

		get exhibitionVenue() {
			return ExhibitorStoreModule.storeSettings.venueNames;
		}
		
		get exhibitionStartDate() {
			return ExhibitorStoreModule.storeSettings.startDate; 
		}

		get exhibitionEndDate() {
			return ExhibitorStoreModule.storeSettings.endDate;
		}

		get eventDate() {
			if (!this.exhibitionStartDate)
				return ;
			
			const startDate =  moment(this.exhibitionStartDate).format( 'LL');
			const endDate = moment(this.exhibitionEndDate).format('LL');
			
			return  startDate === endDate ? startDate : startDate + " - " + endDate;
		}
		
		get currentUser() { return UserModule.user; }
		
		get hideBackButton() {
			return this.routeName === "exhibitions";
		}
		
		created() {
			this.routeName = this.$router.currentRoute.name;
		}
		
		goToLogin() {
			StoreConfigModule.setSliderOpen(SliderNames.login)
		}

		goToExhibitionList() {
			this.$router.push({name:"exhibitions", params:{tenantId: this.tenantId }})
		}
		
		async signOut() {
			await AuthService.logout();
			await UserModule.refreshUser();
			
			const routeName = this.$route.name;
			
			if (ExhibitorStoreModule.storeSettings.tenantId && ExhibitorStoreModule.storeSettings.exhibitionId && routeName !== "user") {
				ExhibitorStoreHelper.configureStore(ExhibitorStoreModule.storeSettings.tenantId, ExhibitorStoreModule.storeSettings.exhibitionId, null)
				if (["orderPreview",
					"orderView",
					"orderPortalPay"
				].includes(routeName)) {
					this.$router.push({name: 'exhibitorStoreCategory', params:{tenantId: ExhibitorStoreModule.storeSettings.tenantId, exhibitionId:ExhibitorStoreModule.storeSettings.exhibitionId, categoryId: ExhibitorStoreModule.lastCategoryId}});
				}
			} else {
				this.$router.push({name:'exhibitions'});
			}
		
		
		}
	}
</script>

<style lang="scss">
    @media print {
        .storeToolbar {
            display: none;
        }
    }
	.backToExList {
	  height: 45px!important;
	  width: 45px;
	  min-width: 45px!important;
	  max-width: 45px;
	  padding: 0px 1px!important;
	}
	
	.mainAppBar {
	  .v-toolbar__title {
		width:226px;
	  }
	  .v-toolbar__content {
		padding: 4px 0px;
	  }

	  .exhibitNameSpace {
		border-color: var(--v-grey-darken1) !important;
		margin-right: 10px;
	  }

	  .exhibitionName {
		margin-right: 10px;
	  }
	  
	  .exhibitionDates {
		margin-left: 10px;
	  }
	  .exhibitionWrap{
		text-align: left;
		margin:0px 0px 5px 5px;
		float: right;
		width:200px;
	  }
	  .exhibitionTitle {
		font-size: medium;
		float: left;
		width: 100%;
		clear: left;
	  }
	  .venueName {
		font-size: small;
		float: left;
		width: 95%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		display: block;
	  }
	  
	  
	}
	
</style>