import axios, { AxiosRequestConfig } from 'axios';
import { StoreConfigModel } from '../../models/generated/storeConfigModel';

export const StoreConfigUrls = {
    getConfig: '/api/StoreConfig/getConfig'
};

export class StoreConfigService {
    public static getConfig(tenantId: string, config: AxiosRequestConfig = {}): Promise<StoreConfigModel> {
        config = {
            ...config,
            params: {
                tenantId
            }
        };
        return axios.get<StoreConfigModel>(StoreConfigUrls.getConfig, config).then(x => x.data);
    }
    
}