import Lang from '../lang';


const en: Lang = {
    "save": "Save",
    "cancel": "Cancel",
    "addValue": "Add {value}",
    "createValueQuotes": "Create '{value}'",
    "valueDoesNotExist": "'{value}' does not exist.",
    "valueWillBeCreated": "'{value}' will be created.",
    "refresh": "Refresh",
    "internalServerError": "An error occurred while processing your request",
    "applicationUpdated": "The application has been updated",
    "close": "Close",
    "file": "No files | File | Files",
    "attachment": "No Attachments | Attachment | Attachments",
    "signOut": "Sign Out",
    "exit": "Exit",
    "editValue": "Edit {value}",
    "next": "Next",
    "remove": "Remove",
    "poweredBy": "Powered by",
    "exhibitorStore": "Exhibitor Store",
    "email": "Email",
    "password": "Password",
    "confirmPassword": "Confirm Password",
    "login": "Login",
    "forgotPassword": "Forgot Password?",
    "loginNeeded": "Please log in to use the Exhibitor Store",
	"noAccount": "Don't have an account?",
	"newAccount": "New Account",
    "signUp": "Sign Up",
    "invalidLogin": "Invalid username or password",
	"day":"day | days",
	"hour":"hour | hours",
    "address": {
        "address": "Address",
        "street": "Street",
        "streetHint": "Street address, P.O. box, c/o",
        "unit": "Unit",
        "unitHint": "Apartment, suite, unit, floor, etc",
        "city": "City",
        "state": "State",
        "zip": "Zip",
        "country": "Country"
    },
    "name": "Name",
    "description": "Description",
    "phone": "Phone",
    "organization": "Organization",
    "emailAlreadyExists": "This email address already exists and cannot be used",
    "emailSentToValue": "We've sent an email to {value}",
	"emailSent": "Email sent",
    "checkJunk": "If you don't see the email, check other places it might be, like your junk, spam, social, or other folders.",
    "backToLogin": "Back to Login",
    "registration": {
        "newAccount": "New Account",
        "register": "Register",
        "clickLinkToActivate": "Click the link in the email to activate your account."
    },
    "lengthUnderMin": "Must be at least {value} characters",
    "invalidToken": "Your activation code is invalid or expired",
    "activate": {
        "activateYourAccount": "Activate your account",
        "passwordsDoNotMatch": "Passwords do not match",
        "activate": "Activate",
        "accountActivated": "Account activated!"
    },
    "sendEmail": "Send Email",
    "reset": {
        "requestPasswordReset": "Request password reset",
        "resetPassword": "Reset password",
        "clickLinkToReset": "Click the link in the email to reset your password.",
        "reset": "Reset",
        "passwordIsReset": "Your password has been reset.",
        "noUserFoundForThisEmail": "No user found for this email address"
    },
    "exhibition": "No Exhibitions | Exhibition | Exhibitions",
    "storeNotActive": "The Exhibitor Store is not active for this account",
    "priceScheduleInvalid": "The price schedule of your order is no longer valid",
    "noExhibitionsAvailable": "No exhibitions are available at this time",
    "exhibitionNotAvailable": "The Exhibitor Store is not available for this event",
	"exhibitorStoreUnavailable":"Exhibitor Store Unavailable",
	"exhibitionList": "Exhibition list",
    "orders": {
        "order": "No Orders | Order | Orders",
        "instruction": "Instruction",
        "price": "Price",
        "unit": "Unit",
        "quantity": "Quantity",
		"timeQuantity":"Time Quantity",
        "tax": "Tax",
        "serviceCharge": "Service Charge",
        "total": "Total",
        "totalTax": "Total Tax",
        "grandTotal": "Grand Total",
        "boothValue": "Booth {value}",
        "orderNumber": "Order Number",
        "totalPaid": "Total Paid",
        "paidOn": "Paid On",
        "pending": "Pending"
    },
    "validation": {
        "fieldRequired": "This field is required",
        "emailMustBeValid": "Email must be valid",
        "valueAlreadyExists": "'{value}' already exists",
        "valueMustBeMin": "Value must be greater than or equal to {value}",
        "valueMustBeMax": "Value must be less than or equal to {value}",
        "invalidDate": "Invalid Date",
        "invalidTime": "Invalid Time",
        "invalidNumber": "Invalid Number",
        "invalidWholeNumber": "Invalid Whole Number",
        "urlMustBeValid": "URL must be valid",
        "invalidName": "Invalid Name",
        "fileSizeExceeded": "File size must be less than {value}mb",
        "invalidFileExtension": "File extension must be {value}",
        "invalidCurrency": "Invalid Currency Format",
        "lengthUnderMin": "Value must be at least {value} characters",
        "lengthExceedsMax": "Value must be {value} characters or less",
        "quantityRequired": "At least 1 item should have a quantity",
    },
    "booth": "Booth",
    "preview": "Preview",
    "confirm": "Confirm",
    "loading": "Loading...",
    "payments": {
        "paymentWasSuccessful": "Your payment of {value} was successful.",
        "paymentReference": "Payment Reference",
        "paymentReferenceValue": "Payment reference: {value}",
        "clickToViewOrder": "Click here to view your order",
        "unableToVerify": "We received your payment but were unable to verify it. Please contact the venue for assistance. Reference: {value}",
        "orderAlreadyPaid": "This order has already been paid.",
		"transactionError": "Notification of your payment could not be sent to the venue. Please contact the venue to alert them of this error.",
		"paymentCancelled": "Your payment has been cancelled.",
		"duplicateTransaction": "This transaction is a duplicate and could not be processed. A possible cause is refreshing the page. If you feel this message is in error, please contact the venue for assistance."
    },
	"department":"No Department | Department | Departments",
	"addToCart":"Add to Cart",
	"editCart":"Edit Cart",
	"updateItem": "Update Item",
	"quickAdd": "Quick Add",
	"checkout":"Check Out",
	"inCart": "In Cart",
	"noItemsInCart":"No items in cart",
	"noSearchResults":"No search results",
	"onsiteContactInfo":"Onsite Contact Information",
	"editContact": "Edit Contact",
	"editItem": "Edit Item",
    "venue": "Venue",
    "item": "No Items | Item | Items",
    "orderHistory": "Order History",
    "home": "Home",
	"pageNotFound": "The page you requested was not found.",
	"more": "More"
};

export default en;