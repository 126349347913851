<template>
		<vops-form @submit="submit()" v-if="isInitialized">
			<vops-slider-pane label="Edit Onsite Contact Info" @cancel="close()">
				<v-container>
					<v-text-field v-model="onsiteContact.name" :label="$t($tl.name)" :rules="[$rules.required]" />
					<v-text-field v-model="onsiteContact.email" :label="$t($tl.email)"
								  :rules="[$rules.required, $rules.email, validEmailRule]"
								  @input="validateEmail"/>
					<v-text-field v-model="onsiteContact.phone" :label="$t($tl.phone)" :rules="[$rules.required]"/>
					<v-text-field v-model="onsiteContact.organization" :label="$t($tl.organization)" :rules="[$rules.required]"/>
					<v-text-field v-model="onsiteContact.address.street" :label="$tc($tl.address.address)" />
					<v-text-field v-model="onsiteContact.address.unit" :label="$tc($tl.address.unitHint)" />
					<div class="d-flex flex-row">
						<v-text-field v-model="onsiteContact.address.locality" :label="$tc($tl.address.city)" />
						<v-text-field class="ml-4" v-model="onsiteContact.address.region" :label="$tc($tl.address.state)" />
					</div>
					<div class="d-flex flex-row">
						<v-text-field v-model="onsiteContact.address.postalCode" :label="$tc($tl.address.zip)" />
						<v-text-field v-model="onsiteContact.address.country"  class="ml-4" :label="$tc($tl.address.country)" />
					</div>
				</v-container>
				</vops-slider-pane>
		</vops-form>
</template>


<script lang="ts">
import {Component,Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {Debounce} from "vue-debounce-decorator";
import {UserService} from "@/services/generated";
import {OnsiteContactModel} from "@/models/generated";
import ExhibitorStoreModule from "@/store/exhibitorStoreModule";

@Component({
	components: {}
})
export default class  extends Vue {
	@Prop () 
	currentOnsiteContact: OnsiteContactModel 	
	
	isInitialized: boolean = false;
	onsiteContact: OnsiteContactModel = null;
	isEmailValid: boolean = false;
	
	@Emit()
	onsiteContactUpdated(){};

	submit() {
		ExhibitorStoreModule.setOnsiteContact({...this.onsiteContact});
		this.onsiteContactUpdated();
	}
	
	@Emit() 
	close() {}

	async created() {
		const currentContact = this.currentOnsiteContact || ExhibitorStoreModule.onsiteContact;
		this.onsiteContact = {...currentContact, address: {...currentContact.address}};
		if (this.onsiteContact.email) {
			this.isEmailValid = true;
		}
		
		this.isInitialized = true;
	}
	
	@Debounce(150)
	async validateEmail() {
			if (!this.onsiteContact.email) {
			this.isEmailValid = true;
			return;
		}
		this.isEmailValid = await UserService.isValidEmail(this.onsiteContact.email);
	}

	get validEmailRule() {
		return this.isEmailValid || this.$tc(this.$tl.emailAlreadyExists);
	}
}
</script>