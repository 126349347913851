import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/config/plugins/store';
import {CurrentUserModel} from "@/models/generated";
import {CurrentUserService} from "@/services/currentUserService";

@Module({ dynamic: true, store: store, namespaced: true, name: "UserModule" })
class User extends VuexModule {
	user: CurrentUserModel = null;

	@Mutation
	private setUser(user: CurrentUserModel) {
		this.user = user;
	}

	@Action
	async bootstrap() {
		const user = await CurrentUserService.get();
		this.setUser(user);
		// this.setCulture(culture);
		// MomentConfig.bootstrap(culture.languageTag, culture.languageOverride);
	}

	@Action
	async refreshUser() {
		const user = await CurrentUserService.get();
		this.setUser(user);
	}
}

const UserModule = getModule(User);
export default UserModule;