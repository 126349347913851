import axios, { AxiosRequestConfig } from 'axios';
import { OrderCostsItemResultModel } from '../../models/generated/orderCostsItemResultModel';
import { GetOrderCostsModel } from '../../models/generated/getOrderCostsModel';

export const OrderCostsUrls = {
    getCosts: '/api/OrderCosts/getCosts'
};

export class OrderCostsService {
    public static getCosts(model: GetOrderCostsModel, config: AxiosRequestConfig = {}): Promise<OrderCostsItemResultModel[]> {
        config = {
            ...config,
        };
        const _data = model;
        return axios.post<OrderCostsItemResultModel[]>(OrderCostsUrls.getCosts, _data, config).then(x => x.data);
    }
    
}