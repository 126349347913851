import Vue from 'vue';
import vopsui from '@/config/plugins/vops-ui';
import vuetify from "@/config/plugins/vuetify";
import '@/config/plugins/store';
import '@/config/plugins/filters';
import i18n from '@/config/plugins/i18n';
import AxiosConfig from '@/config/axios/axiosConfig';
import App from '@/App.vue';
import router from '@/router';
import { StoreConfig } from '@/store';

export default class AppBootstrapper {
	public static async bootstrap() {
		Vue.config.productionTip = false;

		AxiosConfig.bootstrap();
		await StoreConfig.bootstrap();

		new Vue({
			router,
			i18n,
			vuetify,
			vopsui,
			render: (h) => h(App),
		}).$mount('#app');
	}
}