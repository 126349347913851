import Vue from 'vue';
import 'vuetify';
import Vuetify, { colors } from 'vuetify/lib';
import * as components from 'vuetify/lib/components';
import * as directives from 'vuetify/lib/directives';

Vue.use(Vuetify, {
	components,
	directives
});

export default new Vuetify({
	theme: {
		themes: {
			light: {
				vops: "#1c1e61",
				primary: "#2196F3",
				secondary: "#fb8c00",
				accent: "#4146E0",
				anchor: "#4146E0",
				highlight: "#FFF35F",
				grey: { ...colors.grey }
			},
			dark: {
				vops: "#1c1e61",
				primary: "#2196F3",
				secondary: "#fb8c00",
				accent: "#4146E0",
				anchor: "#4146E0",
				highlight: "#FFF35F",
				grey: { ...colors.grey }
			}
		},
		options: {
			customProperties: true
		}
	}
});