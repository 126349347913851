<template>
	<exhibitor-store-layout v-if="isInitialized">
		<router-view/>
	</exhibitor-store-layout>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import ExhibitorStoreLayout from "@/components/exhibitorStore/common/ExhibitorStoreLayout.vue";
import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";
import {ExhibitionService} from "@/services/generated";
import ExhibitorStoreModule from "@/store/exhibitorStoreModule";
import UserModule from "@/store/userModule";

@Component({
	components: {ExhibitorStoreLayout}
})

export default class ExhibitorStore extends Vue {
	isInitialized: boolean = false;
	async created() {
		await this.configureStore();
		this.isInitialized = true;
	}
	
	@Watch("$route.params.tenantId")
	@Watch("$route.params.exhibitorId")
	async configureStore() {
		const {tenantId, exhibitionId} = this.$route.params;
		const userId = UserModule.user ? UserModule.user.id : null;
		
		await ExhibitorStoreHelper.configureStore(tenantId, exhibitionId, userId);
		
		if (ExhibitorStoreModule.storeItems.length === 0)
			this.$router.push({name: 'noExhibitions', params:{tenantId:tenantId}});
	}
}
</script>