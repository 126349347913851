<template>
	<store-page>
		<v-container v-if="isInitialized">
			<vops-form @submit="submit">
			<v-row>
				<v-col md="12">
					<v-card>
						<v-card-title>
							<vops-header :label="order.exhibitionName">
								<template #sublabel>
									<div class="subtitle-2">{{ order.venueNames.join(', ') }}</div>
								</template>
								<div>
									<v-text-field v-model="booth" :label="$t($tl.booth)" @input="onBoothUpdate" :rules="[boothRequiredRule]" />
								</div>
							</vops-header>
						</v-card-title>
						<v-card-text>
							<v-divider/>
							
								<v-simple-table dense>
									<tbody v-for="category in categories" :key="category.id">
									<tr>
										<td class="body-1 font-weight-bold pt-4 pb-1"
											:colspan="getColumnOffset(5)">
											{{ category.name }}
										</td>
									</tr>
									<tr>
										<th>{{ $t($tl.name) }}</th>
										<th class="text-center">{{ $t($tl.orders.quantity) }}</th>
										<th class="text-center" v-if="hasTimeBound">{{ $t($tl.orders.timeQuantity)}}</th>
										<th class="text-right">{{ $t($tl.orders.price) }}</th>
										<th class="text-right">{{ $t($tl.orders.tax) }}</th>
										<th  v-if="hasServiceCharges" class="text-no-wrap  text-right">
											{{ $t($tl.orders.serviceCharge) }}
										</th>
										<th class="text-right">{{ $t($tl.orders.total) }}</th>
									</tr>
									<tr v-for="item in category.items" :key="item.id">
										<td class="orderPreview-name">
											{{ item.name }} - {{ item.unit }}<span v-if="item.instruction"> - {{ item.instruction }}</span>
										</td>
										<td class="text-center">{{ item.quantity }}</td>
										<td v-if="hasTimeBound" class="text-center">
											<template v-if="item.isTimeBound">{{ item.timeQuantity}} {{item.timeQuantityLabel}}</template>
										</td>
										<td class="text-right">{{ item.price | money(order.currency) }}</td>
										<td class="text-right">{{ item.tax | money(order.currency) }}</td>
										<td class="text-right" v-if="hasServiceCharges">
											{{ item.serviceChargeAmount | money(order.currency) }}
										</td>
										<td class="text-right">{{ item.total | money(order.currency) }}</td>
									</tr>
									</tbody>
									<tfoot>
									<tr>
										<td  :colspan="getColumnOffset(4)"
											class="text-right font-weight-bold pt-2">
											{{ $t($tl.orders.totalTax) }}
										</td>
										<td  class="text-right pt-2">{{ totalTax | money(order.currency) }}</td>
									</tr>
									<tr>
										<td :colspan="getColumnOffset(4)" class="text-right font-weight-bold">
											{{ $t($tl.orders.grandTotal) }}
										</td>
										<td class="text-right">{{ grandTotal | money(order.currency) }}</td>
									</tr>
									<tr v-if="order.attachments.length > 0">
										<td :colspan="getColumnOffset(4)" class="text-right font-weight-bold">
											{{ $tc($tl.attachment, 2) }}
										</td>
										<td>{{ order.attachments.length }}</td>
									</tr>
									</tfoot>
								</v-simple-table>
								<div class="d-sm-flex align-end mt-4">
									<div>
										<v-card dense flat>
											<v-card-title>
												{{ $tc($tl.onsiteContactInfo) }}
												<v-btn icon class="ml-3" color="primary" @click="editOnsiteContact">
													<v-icon >edit</v-icon>
												</v-btn> 
											</v-card-title>
											<v-card-text class="contactInfo">
												<h3 class="text-primary">{{onsiteContact.name}}</h3>
												<h5>{{onsiteContact.organization}}</h5>
												<div><span class="contactAddress">{{onsiteContact.address.street}}, </span>
													<span class="contactLocality">{{onsiteContact.address.locality}}, </span> 
													<span class="contactRegion">{{onsiteContact.address.region}}</span> 
													<span class="contactPostalCode">{{onsiteContact.address.postalCode}}</span>
												</div>
												<div>
													<span class="contactEmail">{{onsiteContact.email}}</span>
													<span class="contactPhone">{{onsiteContact.phone}}</span>
												</div>
											</v-card-text>
										</v-card>
									</div>
									
									<div class="d-flex ml-auto d-flex flex-column">
										<template v-if="canEditAttachments">
											<vops-filedrop class="orderPreview-right mt-4" :label="$t($tl.addValue, {value: $tc($tl.attachment, 2)})"
														   @dropped="onDrop" v-if="canEditAttachments"/>
											<v-list dense>
												<v-list-item v-for="(attachment, index) in orderAttachments" :key="index">
													<v-list-item-content>
														<v-list-item-title class="primary--text">
															{{ attachment.name }}
														</v-list-item-title>
													</v-list-item-content>
													<v-list-item-action>
														<v-btn icon color="primary" @click="removeAttachment(attachment)">
															<v-icon>delete</v-icon>
														</v-btn>
													</v-list-item-action>
												</v-list-item>
											</v-list>
										</template>
										<div class="orderPreviewAction">
											<v-btn color="primary" type="submit" :loading="isCreating">
												{{ $t($tl.confirm) }}
											</v-btn>
											<v-btn color="primary" class="ml-3" @click="editOrder">
												{{ $t($tl.editCart)}}
											</v-btn>
										</div>
									</div>
								</div>
							
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			</vops-form>
		</v-container>
		<vops-slider v-model="isEditingOnsiteContact">
			<order-edit-onsite-contact-slider @onsite-contact-updated="onsiteContactUpdated"  @close="closeEditOnsiteContact"  />
		</vops-slider>
	</store-page>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {Debounce} from 'vue-debounce-decorator';
import StorePage from "@/components/common/StorePage.vue";
import UserModule from "@/store/userModule";
import {
	ExhibitionService,
	OrderCostsService,
	OrderService,
	PaymentGatewayService,
	UngerboeckPaymentsService
} from "@/services/generated";
import {
	CreateOrderModel,
	GetOrderCostsModel, OnsiteContactModel,
	OrderCostsItemResultModel
} from "@/models/generated";
import Enumerable from "linq";
import Big from "big.js";
import ExhibitorOrderModule, {OrderAttachment} from "@/store/exhibitorOrder";
import StoreConfigModule, {SliderNames} from "@/store/storeConfigModule";
import User from "@/components/users/User.vue";
import ExhibitorStoreModule from "@/store/exhibitorStoreModule";
import OrderEditOnsiteContactSlider from "@/components/orders/OrderEditOnsiteContactSlider.vue";
import * as ESH from "@/services/exhibitorStoreHelper";
import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";

interface Category {
	id: string;
	name: string;
	items: Item[];
}

interface Item extends OrderCostsItemResultModel {
	name: string;
	quantity: number;
	timeQuantity: number,
	isTimeBound: boolean,
	timeQuantityLabel: string,
	unit: string;
	serviceChargeAmount: number;
	instruction: string;
}

@Component({
	components: {StorePage, OrderEditOnsiteContactSlider }
})
export default class OrderPreview extends Vue {
	categories: Category[] = null;
	totalTax: Big = null;
	grandTotal: Big = null;
	hasServiceCharges: boolean = false;
	hasTimeBound: boolean = false;
	isCreating: boolean = false;
	isInitialized: boolean = false;
	orderAttachments: OrderAttachment[] = [];
	onsiteContact: OnsiteContactModel = null;
	booth: string = null;
	isBoothRequired: boolean = false;
	isEditingOnsiteContact: boolean = false;

	get boothRequiredRule() {
			return  !this.isBoothRequired ||  this.$rules.required(this.booth);
	}
	
	get currentUser() {
		return UserModule.user;
	}
	
	get order() { return ExhibitorOrderModule.order; }
	
	getColumnOffset(columnBase:number) {
		return columnBase + (this.hasServiceCharges ? 1 : 0) + (this.hasTimeBound ? 1: 0);
	}

	get canEditAttachments() { return !this.isCreating && this.order.id == null; }

	editOnsiteContact() {
		this.isEditingOnsiteContact = true;
	}

	closeEditOnsiteContact() {
		this.isEditingOnsiteContact = false;
	}

	getTimeQuantityLabel(timeUnit:number, quantity: number) {
		return ExhibitorStoreHelper.getTimeQuantityLabel(timeUnit, quantity);
	}
	
	async created() {

		const isExhibitionAvailable = await ExhibitionService.isExhibitionAvailableForOrdering(this.order.tenantId, this.order.exhibitionId);
		
		if (!isExhibitionAvailable) {
			this.$router.push({name: 'noExhibitions', params:{tenantId: this.order.tenantId}});
			return;
		}
		
		this.isBoothRequired = ExhibitorStoreModule.storeSettings.isBoothRequired;
		
		const getOrderCosts: GetOrderCostsModel = {
			tenantId: this.order.tenantId,
			priceScheduleId: this.order.priceScheduleId,
			items: this.order.items.map(x => {
				const tQ = parseInt("" + x.timeQuantity);
				
				return {itemId: x.id, quantity: x.quantity, timeQuantity: ExhibitorStoreHelper.getTimeQuantity(x.isTimeBound, tQ)}
			})
		};
		
		const costs = await OrderCostsService.getCosts(getOrderCosts);
		const costsByItemId = Enumerable.from(costs).toDictionary(x => x.itemId);
		
		this.categories = Enumerable.from(this.order.items)
			.groupBy(x => x.categoryId)
			.select(group => {
				const items: Item[] = group.toArray().map(item => ({
					...costsByItemId.get(item.id),
					name: item.name,
					quantity: Math.max(item.quantity,1),
					timeQuantity: ExhibitorStoreHelper.getTimeQuantity(item.isTimeBound, item.timeQuantity),
					isTimeBound: item.isTimeBound,
					unit: item.unit,
					timeQuantityLabel: this.getTimeQuantityLabel(item.timeUnit, item.timeQuantity),
					instruction: item.instruction
				})).sort((a, b) => a.name.localeCompare(b.name));
				const first = group.toArray()[0];
				const category: Category = {
					id: first.categoryId,
					name: first.categoryName,
					items: items
				};
				return category;
			})
			.orderBy(x => x.name)
			.toArray();
		this.orderAttachments = this.order.attachments.slice();

		const allItems: Item[] = Enumerable.from(this.categories).selectMany(x => x.items).toArray();
		this.totalTax = allItems.reduce((prev, current) => prev.plus(current.tax), Big(0));
		this.grandTotal = allItems.reduce((prev, current) => prev.plus(current.total), Big(0));

		this.hasTimeBound = allItems.some(x => x.isTimeBound);
		this.hasServiceCharges =  allItems.some(x => x.serviceChargeAmount > 0);

		if (this.order.onsiteContact) {
			ExhibitorStoreModule.setOnsiteContact({...this.order.onsiteContact})			
		} else {
			this.setDefaultOnsiteContact();
		}

		if (this.order.booth)
			ExhibitorStoreModule.setBooth(this.order.booth);
		
		this.booth = ExhibitorStoreModule.booth;
		
		this.onsiteContactUpdated();
		
		this.isInitialized = true;
	}
	
	onsiteContactUpdated() {
		this.onsiteContact =  ExhibitorStoreModule.onsiteContact;
		this.closeEditOnsiteContact();
	}

	@Debounce(250)
	onBoothUpdate() {
		ExhibitorStoreModule.setBooth(this.booth)
	}
	
	setDefaultOnsiteContact() {
		ExhibitorStoreModule.setOnsiteContact({
				name: this.currentUser.name,
				address: {...this.currentUser.address},
				email: this.currentUser.email,
				organization: this.currentUser.organization,
				phone: this.currentUser.phone
		})
	}

	async editOrder() {
		const storeData:ESH.StoreItemData[] = this.order.items.map(item => {
				return {
					id:item.id,
					instruction: item.instruction,
					quantity: item.quantity,
					timeQuantity: ExhibitorStoreHelper.getTimeQuantity(item.isTimeBound,  item.timeQuantity),
					isInCart: true
				}			
			}
		)
	
		await ExhibitorStoreHelper.restoreOrder(this.order.tenantId, this.order.exhibitionId, this.currentUser.id,  storeData);	
		ExhibitorStoreModule.setOnsiteContact({...this.onsiteContact});	
		
		this.$router.push({name: 'exhibitorStoreCart', params:{tenantId: this.order.tenantId, exhibitionId:this.order.exhibitionId}});
	}
	
	async onDrop(files: File[]) {
		for (const file of files) {
			const base64 = await this.fileToBase64(file);
			const attachment: OrderAttachment = {
				id: null,
				name: file.name,
				base64: base64
			};
			this.orderAttachments.push(attachment);
		}
	}

	async fileToBase64(file: File): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				const result = <string>reader.result;
				const base64 = result.split(',')[1];
				resolve(base64);
			};
			reader.onerror = error => reject(error);
			reader.readAsDataURL(file);
		});
	}

	removeAttachment(attachment: OrderAttachment) {
		this.orderAttachments.remove(attachment);
	}

	async submit() {
			if (this.isCreating)
			return;
		
		try {
			this.isCreating = true;

			let id = this.order.id;

			if (!id) {
				const createModel: CreateOrderModel = {
					tenantId: this.order.tenantId,
					priceScheduleId: this.order.priceScheduleId,
					exhibitionId: this.order.exhibitionId,
					booth: this.booth,
					attachments: this.orderAttachments.map(x => ({
						name: x.name,
						base64: x.base64
					})),
					items: this.order.items.map(x => ({
						itemId: x.id,
						quantity: x.quantity,
						timeQuantity: ExhibitorStoreHelper.getTimeQuantity(x.isTimeBound, x.timeQuantity),
						instruction: x.instruction
					})),
					onsiteContact: {
						name : this.onsiteContact.name,
						email: this.onsiteContact.email,
						phoneNumber: this.onsiteContact.phone,
						organization: this.onsiteContact.organization,
						address: {...this.onsiteContact.address}
					}
				};

				id = await OrderService.create(createModel);
				const createdOrder = await OrderService.get(id);

				await ExhibitorOrderModule.setOrder({
					...this.order,
					attachments: createdOrder.attachments,
					id: id
				});
			}

			const paymentGateway = await PaymentGatewayService.get(this.order.tenantId);
			if (paymentGateway.isSpreedlyGateway || !paymentGateway.isGatewayConfigured) {
				await this.$router.push({name: "orderPortalPay", params: {orderId: id}});
			} else {
				const paymentFormUrl = await UngerboeckPaymentsService.createSession({
					orderId: id
				});
				window.location.assign(paymentFormUrl);
			}
		} catch (e) {
			this.isCreating = false;
		}
	}
}

</script>

<style lang="scss">

.orderPreviewAction {
  display: flex;
  justify-content: space-around;
  margin-top:4px;
}

.orderPreview-name {
  width: 50%;
}

.orderPreview-right {
  min-width: 250px;
}

.contactInfo {
  .contactRegion {
	margin-left: 2px;
  }
  
  .contactPostalCode {
	margin-left: 4px;
  }
  
  .contactPhone {
	margin-left: 10px;
  }

}

</style>