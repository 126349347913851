<template>
	<store-page>
		<v-container>
			<v-row>
				<v-col>
					<v-card>
						<v-card-text v-if="isCreatingPayment">
							<div class="d-flex justify-center align-center mt-4">
								<v-progress-circular indeterminate size="20" class="mr-2"/>
								<div class="title">{{ $t($tl.loading) }}</div>
							</div>
						</v-card-text>
						<v-card-text v-else-if="isDuplicate || isError">
							<v-container>
								<div class="error--text">
									<div v-if="isDuplicate">{{ $t($tl.payments.duplicateTransaction) }}</div>
									<div v-else-if="isError">{{ $t($tl.payments.transactionError) }}</div>
									<div v-if="response.paymentReference">
										{{ $t($tl.payments.paymentReferenceValue, { value: response.paymentReference }) }}
									</div>
								</div>
							</v-container>
						</v-card-text>
						<v-card-text v-else-if="isSuccess">
							<div class="my-6 text-center">
								<v-icon color="success" size="96">check_circle_outline</v-icon>
								<i18n :path="$tl.payments.paymentWasSuccessful" tag="div" class="mt-4">
									<template #value>
										{{ response.amountPaid | money(response.currency) }}
									</template>
								</i18n>
								<div class="mt-2">{{ $t($tl.payments.paymentReferenceValue, {value: response.paymentReference}) }}</div>
								<router-link :to="{ name: 'orderView', params: { orderId: response.orderId } }">
									{{ $t($tl.payments.clickToViewOrder) }}
								</router-link>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</store-page>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {UngerboeckPaymentsService} from "@/services/generated";
import {UngerboeckPaymentsResponseModel} from "@/models/generated";
import StorePage from "@/components/common/StorePage.vue";
import StoreConfigModule from "@/store/storeConfigModule";

@Component({
	components: {StorePage}
})
export default class UngerboeckPaymentsRedirect extends Vue {
	isCreatingPayment: boolean = false;
	response: UngerboeckPaymentsResponseModel = null;
	isDuplicate: boolean = false;
	isError: boolean = false;
	isSuccess: boolean = false;

	async created() {
		const payload = this.$route.query.payload as string;
		if (!payload) {
			await this.$router.push({name: 'notFound'});
			return;
		}
		
		this.isCreatingPayment = true;

		// prevent payload from being re-sent on refresh or back button
		window.history.replaceState({}, "", window.location.pathname);
		
		// use fallback culture
		await StoreConfigModule.clearConfig();

		try {
			this.response = await UngerboeckPaymentsService.getResponse(payload);
			await UngerboeckPaymentsService.setPaid({payload});
			this.isSuccess = true;
		} catch (error: any) {
			if (error.status === 422) {
				this.isDuplicate = true;
			} else {
				this.isError = true;
			}
		} finally {
			this.isCreatingPayment = false;
		}
	}
}
</script>