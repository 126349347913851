import axios, { AxiosRequestConfig } from 'axios';
import { ExhibitionWebModel } from '../../models/generated/exhibitionWebModel';

export const ExhibitionsUrls = {
    getExhibitionsAvailableForOrdering: '/api/Exhibitions/getExhibitionsAvailableForOrdering'
};

export class ExhibitionsService {
    public static getExhibitionsAvailableForOrdering(tenantId: string, venueId: string, config: AxiosRequestConfig = {}): Promise<ExhibitionWebModel[]> {
        config = {
            ...config,
            params: {
                tenantId,
                venueId
            }
        };
        return axios.get<ExhibitionWebModel[]>(ExhibitionsUrls.getExhibitionsAvailableForOrdering, config).then(x => x.data);
    }
    
}