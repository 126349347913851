<template>
	<div>
		<vops-slider v-model="loginSlider">
			<user-login-slider @user-logged-in="onUserLogin" @close="closeLogin" :login-email="email" />
		</vops-slider>
		<vops-slider v-model="forgotPasswordSlider">
			<user-forgot-password-slider @email-sent="resetEmailSent" @close="closeForgotPassword"  />
		</vops-slider>
		<vops-slider v-model="resetEmailSentSlider">
			<user-reset-email-sent :email="email" @submit="goToLogin" @close="closeResetEmailSentSlider"  />
		</vops-slider>
		<vops-slider v-model="registerNewAccount">
			<user-registration-slider  @close="closeRegistration" @goto-login="goToLogin" />
		</vops-slider>
	</div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import StoreConfigModule, {SliderNames, SubscriberAction} from "@/store/storeConfigModule";
import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";
import UserLoginSlider from "@/components/users/UserLoginSlider.vue";
import UserForgotPasswordSlider from "@/components/users/UserForgotPasswordSlider.vue";
import UserResetEmailSent from "@/components/users/UserResetEmailSent.vue";
import UserRegistrationSlider from "@/components/users/UserRegistrationSlider.vue";

@Component({
	components: {
		UserRegistrationSlider,
		UserResetEmailSent, 
		UserForgotPasswordSlider, 
		UserLoginSlider}
})
export default class StoreSliders extends Vue {
	isInitialized: boolean = true;
	loginSlider: boolean = false;
	forgotPasswordSlider: boolean = false;
	resetEmailSentSlider: boolean = false;
	email:string = null;
	unsubscribe = null;
	registerNewAccount: boolean = false;
	
	subscriptionAction(action) {
		if (action.type !== "StoreConfigModule/subscriberEvent")
			return;

		if (action.payload === SubscriberAction.sliders_updated)
			this.updateSlider();
	}

	created() {
		this.unsubscribe = ExhibitorStoreHelper.subscribeToStore(this.subscriptionAction);
	}

	beforeDestroy() {
		this.unsubscribe();
	}

	onUserLogin(email:string = null) {
		this.email = email;
		this.closeLogin();
	}
	
	updateSlider() {
		this.loginSlider = StoreConfigModule.sliders.login;
		this.forgotPasswordSlider = StoreConfigModule.sliders.forgotPassword;
		this.resetEmailSentSlider = StoreConfigModule.sliders.resetEmailSent;
		this.registerNewAccount = StoreConfigModule.sliders.registerNewAccount;
	}

	resetEmailSent(email) {
		this.closeSliders();
		this.email = email;
		StoreConfigModule.setSliderOpen(SliderNames.resetEmailSent);
	}

	goToLogin(email:string = null) {
		this.email = email;
		this.closeSliders();
		StoreConfigModule.setSliderOpen(SliderNames.login);
	}
	
	closeLogin() {
		StoreConfigModule.setSliderClosed(SliderNames.login);
	}

	closeForgotPassword() {
		StoreConfigModule.setSliderClosed(SliderNames.forgotPassword);
	}

	closeResetEmailSentSlider() {
		StoreConfigModule.setSliderClosed(SliderNames.resetEmailSent);
	}
	
	closeRegistration() {
		StoreConfigModule.setSliderClosed(SliderNames.registerNewAccount);
	}

	closeSliders() {
		this.closeLogin();
		this.closeForgotPassword();
		this.closeResetEmailSentSlider();
		this.closeRegistration();
	}
}
</script>