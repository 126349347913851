import Vue from 'vue';
import VopsUI from '@eventbooking/vops-ui';
import i18n, { $lang } from './i18n';

Vue.use(VopsUI);

export default new VopsUI({
	labels: {
		save: () => i18n.tc($lang.save),
		cancel: () => i18n.tc($lang.cancel),
		loading: () => i18n.tc($lang.loading),
		filedrop: {
			addFiles: () => i18n.tc($lang.addValue, null, {value: i18n.tc($lang.file, 2)}),
			fileSizeExceeded: value => i18n.tc($lang.validation.fileSizeExceeded, null, {value}),
			invalidFileExtension: value => i18n.tc($lang.validation.invalidFileExtension, null, {value})
		},
		combobox: {
			createValueQuotes: value => i18n.tc($lang.createValueQuotes, null, {value}),
			valueDoesNotExist: value => i18n.tc($lang.valueDoesNotExist, null, {value}),
			valueWillBeCreated: value => i18n.tc($lang.valueWillBeCreated, null, {value})
		},
		fieldRequired: () => i18n.tc($lang.validation.fieldRequired),
		invalidTime: () => i18n.tc($lang.validation.invalidTime),
		invalidDate: () => i18n.tc($lang.validation.invalidDate),
		invalidCurrency: () => i18n.tc($lang.validation.invalidCurrency),
		emailMustBeValid: () => i18n.tc($lang.validation.emailMustBeValid),
		urlMustBeValid: () => i18n.tc($lang.validation.urlMustBeValid),
		valueMustBeMin: value => i18n.tc($lang.validation.valueMustBeMin, null, {value}),
		valueMustBeMax: value => i18n.tc($lang.validation.valueMustBeMax, null, {value}),
		lengthUnderMin: value => i18n.tc($lang.validation.lengthUnderMin, null, {value}),
		lengthExceedsMax: value => i18n.tc($lang.validation.lengthExceedsMax, null, {value}),
		invalidNumber: () => i18n.tc($lang.validation.invalidNumber),
		invalidWholeNumber: () => i18n.tc($lang.validation.invalidWholeNumber),
		valueAlreadyExists: value => i18n.tc($lang.validation.valueAlreadyExists, null, {value})
	}
});