<template>
	<store-page>
		<v-container v-if="isInitialized">
			<v-row>
				<v-col md="12">
					<v-card v-if="!error">
						<v-card-title>
							<vops-header :label="order.exhibition.name">
								<template #sublabel>
									<div class="subtitle-2">{{ order.exhibition.venueNames.join(', ') }}</div>
									<div v-if="order.booth" class="subtitle-2">
										{{ $t($tl.orders.boothValue, {value: order.booth}) }}
									</div>
								</template>
							</vops-header>
						</v-card-title>
						<v-card-text v-if="!isPaid && !errorReference">
							<v-divider/>
							<div class="headline black--text text-center mt-4">
								{{ order.grandTotal | money(order.currency) }}
							</div>
							<payment-portal :request-id="requestId" @success="paymentSuccess"/>
						</v-card-text>
						<v-card-text v-else-if="isPaid && !errorReference">
							<v-divider/>
							<div class="text-center">
								<v-icon color="success" size="96">check</v-icon>
								<i18n :path="$tl.payments.paymentWasSuccessful" tag="div">
									<template #value>
										{{ receipt.amount | money(receipt.currency) }}
									</template>
								</i18n>
								<div class="mt-2">{{ $t($tl.payments.paymentReferenceValue, {value: receipt.reference}) }}</div>
								<router-link :to="{ name: 'orderView', params: { orderId: order.id } }">
									{{ $t($tl.payments.clickToViewOrder) }}
								</router-link>
							</div>
						</v-card-text>
						<v-card-text v-else>
							<v-divider/>
							<v-container>{{ $t($tl.payments.unableToVerify, {value: errorReference}) }}</v-container>
						</v-card-text>
					</v-card>
					<v-card v-else>
						<v-card-text>
							<v-container>{{ error }}</v-container>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</store-page>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import StorePage from "@/components/common/StorePage.vue";
import {OrderModel} from "@/models/generated";
import {ExhibitionService, OrderService, PaymentsPortalService} from "@/services/generated";
import PaymentPortal, {Receipt} from "@/components/portal/PaymentPortal.vue";
import ExhibitorOrderModule from "@/store/exhibitorOrder";
import StoreConfigModule from "@/store/storeConfigModule";
import ExhibitorStoreModule from "@/store/exhibitorStoreModule";

@Component({
	components: {PaymentPortal, StorePage}
})
export default class OrderPortalPay extends Vue {
	order: OrderModel = null;
	requestId: string = null;
	isInitialized: boolean = false;
	error: string = null;
	isPaid: boolean = false;
	receipt: Receipt = null;
	errorReference: string = null;

	get storeConfig() {
		return StoreConfigModule.storeConfig;
	}

	async created() {
		const orderId = this.$route.params.orderId;
		this.order = await OrderService.get(orderId);

		const [isAvailable, prices] = await Promise.all([
			ExhibitionService.isExhibitionAvailableForOrdering(this.order.tenantId, this.order.exhibition.exhibitionId),
			ExhibitionService.getCurrentPrices(this.order.tenantId, this.order.exhibition.exhibitionId)
		]);
		await StoreConfigModule.updateConfig(this.order.tenantId);

		if (!this.storeConfig.isStoreActive) {
			this.error = this.$tc(this.$tl.storeNotActive);
		} else if (!isAvailable) {
			this.error = this.$tc(this.$tl.exhibitionNotAvailable);
		} else if (this.order.priceScheduleId !== prices.priceScheduleId) {
			this.error = this.$tc(this.$tl.priceScheduleInvalid)
		} else if (this.order.isPaid) {
			this.error = this.$tc(this.$tl.payments.orderAlreadyPaid);
		} else {
			this.requestId = await PaymentsPortalService.createPaymentRequest({orderId});
		}

		this.isInitialized = true;
	}

	async paymentSuccess(receipt: Receipt) {
		try {
			await PaymentsPortalService.setPaid({
				orderId: this.order.id,
				verificationId: receipt.verificationId
			});

			this.isPaid = true;
			this.receipt = receipt;
			
			ExhibitorStoreModule.clearSavedUserCartForExhibition(ExhibitorStoreModule.storeSettings.cartKey);
		} catch (e) {
			this.errorReference = receipt.reference;
		}

		ExhibitorOrderModule.clearOrder();
	}
}
</script>