<template>
	<exhibitor-store-item-display 
			v-if="isInitialized"
			:store-item-list="categoryItems" 
			:isLoading="isLoading" 
			:currency="currency"
			:category-information = "categoryInformation"
	/>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import ExhibitorStoreItemDisplay from "@/components/exhibitorStore/common/ExhibitorStoreItemDisplay.vue";
import StoreConfigModule from "@/store/storeConfigModule";
import {ExhibitionService} from "@/services/generated";
import ExhibitorOrderModule from "@/store/exhibitorOrder";
import Enumerable from "linq";
import ExhibitorStoreModule from "@/store/exhibitorStoreModule";
import ExhibitorStoreHelper, * as ESH  from "@/services/exhibitorStoreHelper";

@Component({
	components: {ExhibitorStoreItemDisplay}
})

export default class ExhibitorStoreCategoryDisplay  extends Vue {
	isInitialized: boolean = false;
	tenantId: string = null;
	isExhibitionAvailable: boolean = false;
	exhibition = null ;
	exhibitionId: string = null;
	categoryId: string = null;
	currency: string = null;
	categoryItems: string[] = [];
	categoryInformation:ESH.CategoryInfo  = null;
	isLoading: boolean = false;

	get storeSettings() {
		return ExhibitorStoreModule.storeSettings;
	}

	async created() {
		ExhibitorStoreHelper.clearSearchText();
		await this.loadPrices();
		this.isInitialized = true;
	}
	
	@Watch('$route.params.categoryId', {immediate: true})
	async loadPrices() {
		let categoryId = this.$route.params.categoryId;
	
		if (!categoryId) {
			categoryId =  ExhibitorStoreModule.lastCategoryId ||  ExhibitorStoreModule.departmentInfo[0].categories[0].id;
			this.$router.push({name: 'exhibitorStoreCategory', params:{tenantId: this.storeSettings.tenantId, exhibitorId:this.storeSettings.exhibitionId, categoryId: categoryId}});
			return;
		}
		
		this.currency = this.storeSettings.currency;
		this.categoryItems = ExhibitorStoreHelper.getStoreItemIdsByCategoryId(categoryId)
		this.categoryInformation = this.getCategoryInformation(categoryId);	
		
		if (categoryId !== ExhibitorStoreModule.lastCategoryId) {
			ExhibitorStoreModule.setLastCategoryId(categoryId);
		}
		
		this.isLoading = false;
	}

	getCategoryInformation(categoryId:string) {
		if (ExhibitorStoreModule.departmentInfo.length === 0)
			return null;
		
	 	return ExhibitorStoreModule.departmentInfo.flatMap(dep => dep.categories).find(cat => cat.id===categoryId);
	}
}

interface StoreItem {
	name: string
}
</script>