import axios, { AxiosRequestConfig } from 'axios';
import { UngerboeckPaymentsResponseModel } from '../../models/generated/ungerboeckPaymentsResponseModel';
import { CreateUngerboeckPaymentsSessionModel } from '../../models/generated/createUngerboeckPaymentsSessionModel';
import { SetOrderPaidFromUngerboeckPaymentsModel } from '../../models/generated/setOrderPaidFromUngerboeckPaymentsModel';

export const UngerboeckPaymentsUrls = {
    getResponse: '/api/UngerboeckPayments/getResponse',
    createSession: '/api/UngerboeckPayments/createSession',
    setPaid: '/api/UngerboeckPayments/setPaid'
};

export class UngerboeckPaymentsService {
    public static getResponse(payload: string, config: AxiosRequestConfig = {}): Promise<UngerboeckPaymentsResponseModel> {
        config = {
            ...config,
            params: {
                payload
            }
        };
        return axios.get<UngerboeckPaymentsResponseModel>(UngerboeckPaymentsUrls.getResponse, config).then(x => x.data);
    }
    
    public static createSession(model: CreateUngerboeckPaymentsSessionModel, config: AxiosRequestConfig = {}): Promise<string> {
        config = {
            ...config,
        };
        const _data = model;
        return axios.post<string>(UngerboeckPaymentsUrls.createSession, _data, config).then(x => x.data);
    }
    
    public static setPaid(model: SetOrderPaidFromUngerboeckPaymentsModel, config: AxiosRequestConfig = {}): Promise<ArrayBuffer> {
        config = {
            responseType: 'arraybuffer',
            ...config,
        };
        const _data = model;
        return axios.put<ArrayBuffer>(UngerboeckPaymentsUrls.setPaid, _data, config).then(x => x.data);
    }
    
}