import axios, { AxiosRequestConfig } from 'axios';
import { CultureModel } from '../../models/generated/cultureModel';

export const CultureUrls = {
    getCulture: '/api/Culture/getCulture'
};

export class CultureService {
    public static getCulture(config: AxiosRequestConfig = {}): Promise<CultureModel> {
        config = {
            ...config,
        };
        return axios.get<CultureModel>(CultureUrls.getCulture, config).then(x => x.data);
    }
    
}