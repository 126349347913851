<template>
    <store-page>
        <v-container v-if="isInitialized">
            <v-row justify="center">
                <v-col md="6">
                    <v-card v-if="!isActivated">
                        <v-card-title>
                            <vops-header :label="$t($tl.activate.activateYourAccount)"/>
                        </v-card-title>
                        <v-card-text>
                            <v-divider/>
                            <vops-form @submit="submit">
                                <v-container>
                                    <v-text-field v-model="password" :label="$t($tl.password)" type="password"
                                                  :rules="[$rules.required, $rules.minLength(8)]" v-focus/>
                                    <v-text-field v-model="confirmPassword" :label="$t($tl.confirmPassword)"
                                                  type="password" :rules="[passwordMatchRule]"/>
                                    <v-btn type="submit" color="primary" class="mt-2" :loading="isSaving" block>
                                        {{ $t($tl.activate.activate) }}
                                    </v-btn>
                                    <div v-if="isInvalidToken" class="mt-4 text-center error--text">
                                        {{ $t($tl.invalidToken) }}
                                    </div>
                                </v-container>
                            </vops-form>
                        </v-card-text>
                    </v-card>
                    <v-card v-else>
                        <v-card-text>
                            <v-container>
                                <div class="text-center">
                                    <v-icon color="success" size="96">check</v-icon>
                                    <div class="mt-2">{{ $t($tl.activate.accountActivated) }}</div>
                                    <div class="mt-4">
                                        <router-link :to="{ name: 'login' }">{{ $t($tl.backToLogin) }}</router-link>
                                    </div>
                                </div>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </store-page>
</template>

<script lang="ts">
    import {Component, Vue} from "vue-property-decorator";
    import StorePage from "@/components/common/StorePage.vue";
    import {ActivateUserModel} from "@/models/generated";
    import {UserService} from "@/services/generated";
    import StoreConfigModule from "@/store/storeConfigModule";

    @Component({
        components: {StorePage}
    })
    export default class UserActivate extends Vue {
        password: string = null;
        confirmPassword: string = null;
        isSaving: boolean = false;
        isActivated: boolean = false;
        isInvalidToken: boolean = false;
        isInitialized: boolean = false;

        async created() {
            await StoreConfigModule.clearConfig();
            this.isInitialized = true;
        }

        async submit() {
            if (this.isSaving)
                return;

            try {
                this.isSaving = true;
                const activationToken = this.$route.params.activationToken;

                const model: ActivateUserModel = {
                    password: this.password,
                    token: activationToken
                };
                await UserService.activateUser(model);
                
                this.isSaving = false;
                this.isActivated = true;
            } catch (e) {
                this.isSaving = false;
                this.isInvalidToken = true;
            }
        }

        get passwordMatchRule() {
            return this.password === this.confirmPassword || this.$tc(this.$tl.activate.passwordsDoNotMatch);
        }
    }
</script>