import axios, { AxiosRequestConfig } from 'axios';
import {CurrentUserModel} from "@/models/generated";

export const CurrentUserUrls = {
    get: '/api/CurrentUser/get'
};

export class CurrentUserService {
    public static async get(config: AxiosRequestConfig = {}) {
        config = {
            ...config,
        };
        const result = await axios.get<CurrentUserModel>(CurrentUserUrls.get, config);
        return result.status === 204 ? null : result.data;
    }
}