<template>
	<div>
		<div v-if="!iframeInitialized" class="d-flex justify-center align-center mt-4">
			<v-progress-circular indeterminate size="20" class="mr-2"/>
			<div class="title">{{ $t($tl.loading) }}</div>
		</div>
		<iframe :src="getPaymentFormUrl()"
				class="paymentPortal-iframe"
				:style="{ height: height }">
		</iframe>
		
	</div>
</template>

<script lang="ts">
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import EnvModule from "@/store/envModule";

export interface Receipt {
	verificationId: string;
	reference: string;
	isSuccess: boolean;
	error: string;
	amount: number;
	currency: string;
}

@Component
export default class PaymentPortal extends Vue {
	@Prop({type: String, required: true}) requestId: string;
	@Emit() paying() { }
	@Emit() success(receipt: Receipt) { return receipt; }
	@Emit() error() { }

	iframeInitialized: boolean = false;
	height: string = "0";

	get paymentsPortal() {
		return EnvModule.environment.paymentsPortal;
	}

	created() {
		window.addEventListener("message", this.onMessage);
	}

	destroyed() {
		window.removeEventListener("message", this.onMessage);
	}

	getPaymentFormUrl() {
		const requestId = encodeURIComponent(this.requestId);
		return `${this.paymentsPortal}/pay/${requestId}`;
	}

	onMessage(evt) {
		const origin = evt.origin;
		if (!this.paymentsPortal.includes(origin))
			return;

		const {type, value} = evt.data;
		switch (type) {
			case "paymentForm:initialized":
				const height = value.height;
				this.height = `${height}px`;
				this.iframeInitialized = true;
				break;
			case "paymentForm:paying":
				this.paying();
				break;
			case "paymentForm:paySuccess":
				this.success(value);
				break;
			case "paymentForm:payError":
				this.error();
				break;
		}
	}
}
</script>

<style lang="scss">

.paymentPortal-iframe {
	width: 100%;
	border: 0;
}

</style>