import axios, { AxiosRequestConfig } from 'axios';
import { OrderModel } from '../../models/generated/orderModel';
import { CreateOrderModel } from '../../models/generated/createOrderModel';

export const OrderUrls = {
    get: '/api/Order/get',
    create: '/api/Order/create',
    download: '/api/Order/download'
};

export class OrderService {
    public static get(id: string, config: AxiosRequestConfig = {}): Promise<OrderModel> {
        config = {
            ...config,
            params: {
                id
            }
        };
        return axios.get<OrderModel>(OrderUrls.get, config).then(x => x.data);
    }
    
    public static create(model: CreateOrderModel, config: AxiosRequestConfig = {}): Promise<string> {
        config = {
            ...config,
        };
        const _data = model;
        return axios.post<string>(OrderUrls.create, _data, config).then(x => x.data);
    }
    
    public static download(orderId: string, attachmentId: string, config: AxiosRequestConfig = {}): Promise<ArrayBuffer> {
        config = {
            responseType: 'arraybuffer',
            ...config,
            params: {
                orderId,
                attachmentId
            }
        };
        return axios.get<ArrayBuffer>(OrderUrls.download, config).then(x => x.data);
    }
    
}