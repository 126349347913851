import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/config/plugins/store';

@Module({ dynamic: true, store: store, namespaced: true, name: "AxiosMessageModule" })
class AxiosMessage extends VuexModule {
	error: string = null;
	refresh: boolean = false;

	@Mutation
	private setError(error: string) {
		this.error = error;
	}

	@Mutation
	private setRefresh(refresh: boolean) {
		this.refresh = refresh;
	}

	@Action
	updateError(error: string) {
		this.setError(error);
	}

	@Action
	clearError() {
		this.setError(null);
	}

	@Action
	updateRefresh(refresh: boolean) {
		this.setRefresh(refresh);
	}
}

const AxiosMessageModule = getModule(AxiosMessage);
export default AxiosMessageModule;