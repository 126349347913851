<template>
    <store-page>
        <v-container v-if="isInitialized">
            <v-row justify="center">
                <v-col md="6">
                    <v-card v-if="!isAwaitingVerification">
                        <v-card-title>
                            <vops-header :label="$t($tl.registration.newAccount)"/>
                        </v-card-title>
                        <v-card-text>
                            <v-divider/>
                            <vops-form @submit="submit">
                                <v-container>
                                    <v-text-field v-model="name" :label="$t($tl.name)" :rules="[$rules.required]" v-focus/>
                                    <v-text-field v-model="email" :label="$t($tl.email)"
                                                  :rules="[$rules.required, $rules.email, validEmailRule]"
                                                  @input="validateEmail"/>
                                    <v-text-field v-model="phone" :label="$t($tl.phone)" :rules="[$rules.required]"/>
                                    <v-text-field v-model="organization" :label="$t($tl.organization)"
                                                  :rules="[$rules.required]"/>
                                    <form-address v-model="billingAddress"/>
                                    <v-btn type="submit" color="primary" :loading="isSaving" block>
                                        {{ $t($tl.registration.register) }}
                                    </v-btn>
                                    <div class="mt-4 text-center">
                                        <router-link :to="{ name: 'login' }">{{ $t($tl.backToLogin) }}</router-link>
                                    </div>
                                </v-container>
                            </vops-form>
                        </v-card-text>
                    </v-card>
                    <v-card v-else>
                        <v-card-text>
                            <v-container>
                                <div class="text-center">
                                    <v-icon color="success" size="96">check</v-icon>
                                    <div class="mt-2 font-weight-bold">{{ $t($tl.emailSentToValue, { value: this.email }) }}</div>
                                    <div class="mt-4">{{ $t($tl.registration.clickLinkToActivate) }}</div>
                                    <div class="mt-4">{{ $t($tl.checkJunk) }}</div>
                                </div>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </store-page>
</template>

<script lang="ts">
    import {Component, Vue} from "vue-property-decorator";
    import FormAddress, {Address} from "@/components/common/FormAddress.vue";
    import {UserService} from "@/services/generated";
    import {CreateUserModel} from "@/models/generated";
    import {Debounce} from "vue-debounce-decorator";
    import StorePage from "@/components/common/StorePage.vue";
    import StoreConfigModule from "@/store/storeConfigModule";

    @Component({
        components: {StorePage, FormAddress}
    })
    export default class UserRegistration extends Vue {
        name: string = null;
        email: string = null;
        phone: string = null;
        organization: string = null;
        billingAddress: Address = {
            street: null,
            unit: null,
            locality: null,
            region: null,
            postalCode: null,
            country: null
        };
        isEmailValid: boolean = false;
        isAwaitingVerification: boolean = false;
        isSaving: boolean = false;
        isInitialized: boolean = false;

        async created() {
            await StoreConfigModule.clearConfig();
            this.isInitialized = true;
        }

        async submit() {
            if (this.isSaving)
                return;

            try {
                this.isSaving = true;

                const model: CreateUserModel = {
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                    organization: this.organization,
                    billingAddress: this.billingAddress
                };
                await UserService.createUser(model);

                this.isSaving = false;
                this.isAwaitingVerification = true;
            } catch (e) {
                this.isSaving = false;
            }
        }

        @Debounce(150)
        async validateEmail() {
            if (!this.email) {
                this.isEmailValid = true;
                return;
            }
            this.isEmailValid = await UserService.isValidEmail(this.email);
        }

        get validEmailRule() {
            return this.isEmailValid || this.$tc(this.$tl.emailAlreadyExists);
        }
    }
</script>