import axios, { AxiosRequestConfig } from 'axios';
import { EnvironmentModel } from '../../models/generated/environmentModel';

export const EnvironmentUrls = {
    get: '/api/Environment/get'
};

export class EnvironmentService {
    public static get(config: AxiosRequestConfig = {}): Promise<EnvironmentModel> {
        config = {
            ...config,
        };
        return axios.get<EnvironmentModel>(EnvironmentUrls.get, config).then(x => x.data);
    }
    
}