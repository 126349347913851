import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/config/plugins/store';
import {OnsiteContactModel} from "@/models/generated";

@Module({ dynamic: true, store: store, namespaced: true, name: "ExhibitorOrderModule" })
class ExhibitorOrder extends VuexModule {
    private _key: string = "exhibitorOrder";
    order: Order = null;

    @Mutation
    setOrder(order: Order) {
        this.order = order;
    }
    
    @Action
    updateOrder(order: Order) {
        const json = JSON.stringify(order);
        window.localStorage.setItem(this._key, json);
        this.setOrder(order);
    }

    @Action
    clearOrder() {
        window.localStorage.removeItem(this._key);
        this.setOrder(null);
    }
    
    @Action
    bootstrap() {
        const json = window.localStorage.getItem(this._key);
        if (json) {
            const order = JSON.parse(json) as Order;
            this.setOrder(order);
        }
    }
}

export interface Order {
    id: string;
    tenantId: string;
    booth: string;
    exhibitionId: string;
    exhibitionName: string;
    venueNames: string[];
    priceScheduleId: string;
    currency: string;
	onsiteContact?: OnsiteContactModel;
    items: OrderItem[];
    attachments: OrderAttachment[];
}

export interface OrderItem {
    id: string;
    quantity: number;
	timeQuantity?:number;
	isTimeBound?: boolean;
    name: string;
    unit: string;
	timeUnit?: number;
    categoryId: string;
    categoryName: string;
    instruction: string;
}

export interface OrderAttachment {
	id: string;
    name: string;
    base64?: string;
}

const ExhibitorOrderModule = getModule(ExhibitorOrder);
export default ExhibitorOrderModule;