<template>
    <store-page>
        <v-container v-if="isInitialized">
            <v-row justify="center">
                <v-col md="6">
                    <v-card>
                        <v-card-text>
                            <vops-form @submit="submit">
                                <div class="text-center">
                                    <v-icon size="96">person_pin</v-icon>
                                </div>

                                <v-text-field v-model="email" :label="$t($tl.email)" :rules="[$rules.required, $rules.email]" v-focus/>
                                <v-text-field v-model="password" :label="$t($tl.password)" type="password"
                                              :rules="[$rules.required]"/>

                                <div v-if="isAuthError" class="error--text">{{$t($tl.invalidLogin)}}</div>

                                <v-btn class="mt-4" color="primary" type="submit" :loading="isLoggingIn" block>
                                    {{ $t($tl.login) }}
                                </v-btn>

                                <v-btn class="mt-4" block :to="{ name: 'forgotPassword' }">
                                    {{ $t($tl.forgotPassword) }}
                                </v-btn>

                                <div class="mt-4 text-center">
                                    <span>{{$t($tl.noAccount)}} </span>
                                    <router-link :to="{ name: 'registration' }">{{$t($tl.signUp)}}</router-link>
                                </div>
                            </vops-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </store-page>
</template>

<script lang="ts">
    import {Component, Vue} from "vue-property-decorator";
    import {AuthService} from "@/services/generated";
    import {LoginModel} from "@/models/generated";
    import UserModule from "@/store/userModule";
    import StorePage from "@/components/common/StorePage.vue";
    import ExhibitorOrderModule from "@/store/exhibitorOrder";
    import StoreConfigModule from "@/store/storeConfigModule";

    @Component({
        components: {StorePage}
    })
    export default class UserLogin extends Vue {
        email: string = null;
        password: string = null;
        isLoggingIn: boolean = false;
        isAuthError: boolean = false;
        isInitialized: boolean = false;

        async created() {
            await StoreConfigModule.clearConfig();
            this.isInitialized = true;
        }

        async submit() {
            if (this.isLoggingIn)
                return;

            try {
                this.isLoggingIn = true;
                this.isAuthError = false;

                const model: LoginModel = {
                    email: this.email,
                    password: this.password
                };
                await AuthService.login(model);
                await UserModule.refreshUser();

                await this.redirect();
            } catch (e) {
                this.isLoggingIn = false;
                this.isAuthError = true;
            }
        }

        async redirect() {
            if (ExhibitorOrderModule.order != null) {
                await this.$router.push({ name: "orderPreview" });
            } else {
                await this.$router.push({ name: "user" });
            }
        }
    }
</script>