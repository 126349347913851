import moment from 'moment';
window['moment'] = moment;

export default class MomentConfig {
	public static bootstrap(languageTag: string, languageOverride: string) {
		if (languageTag === 'zh-hans-cn')
			languageTag = 'zh-cn';

		const overrideData = moment.localeData(languageOverride);
		const localeName = moment.locale(languageTag);

		const {_arLongDateFormat}: any = moment.localeData('ar');

		moment.updateLocale('ar', {
			preparse: undefined,
			postformat: undefined,
			longDateFormat: {
				..._arLongDateFormat,
				L: 'D/M/YYYY'
			}
		});

		moment.updateLocale(localeName, {
			invalidDate: overrideData.invalidDate(),
			months: overrideData.months(),
			monthsShort: overrideData.monthsShort(),
			weekdays: overrideData.weekdays(),
			weekdaysMin: overrideData.weekdaysMin(),
			weekdaysShort: overrideData.weekdaysShort()
		});

		moment.defaultFormat = "YYYY-MM-DDTHH:mm:ss";
	}
}