<template>
    <v-footer class="storeFooter">
        <div class="d-flex justify-center align-center flex-grow-1">
            <div class="body-2">{{ $t($tl.poweredBy) }}</div>
            <a target="_blank" href="https://gomomentus.com/">
                <img src="/images/logo.png" alt="VenueOps" class="storeFooter-logo"/>
            </a>
        </div>
    </v-footer>
</template>

<script lang="ts">
	import {Component, Vue} from "vue-property-decorator";

	@Component
	export default class StoreFooter extends Vue {
	}
</script>

<style lang="scss">
    @media print {
        .storeFooter {
            display: none;
        }
    }

    .storeFooter-logo {
        display: block;
        height: 1.25em;
        margin-left: .2em;
        padding-top: 3px;
    }
</style>