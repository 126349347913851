import axios, { AxiosRequestConfig } from 'axios';
import { LoginModel } from '../../models/generated/loginModel';

export const AuthUrls = {
    isAuthenticated: '/api/Auth/isAuthenticated',
    login: '/api/Auth/login',
    logout: '/api/Auth/logout'
};

export class AuthService {
    public static isAuthenticated(config: AxiosRequestConfig = {}): Promise<boolean> {
        config = {
            ...config,
        };
        return axios.get<boolean>(AuthUrls.isAuthenticated, config).then(x => x.data);
    }
    
    public static login(model: LoginModel, config: AxiosRequestConfig = {}): Promise<ArrayBuffer> {
        config = {
            responseType: 'arraybuffer',
            ...config,
        };
        const _data = model;
        return axios.post<ArrayBuffer>(AuthUrls.login, _data, config).then(x => x.data);
    }
    
    public static logout(config: AxiosRequestConfig = {}): Promise<ArrayBuffer> {
        config = {
            responseType: 'arraybuffer',
            ...config,
        };
        return axios.post<ArrayBuffer>(AuthUrls.logout, config).then(x => x.data);
    }
    
}