import Vue from 'vue';
import Router from 'vue-router';
import User from "@/components/users/User.vue";
import UserLogin from "@/components/users/UserLogin.vue";
import UserRegistration from "@/components/users/UserRegistration.vue";
import UserActivate from "@/components/users/UserActivate.vue";
import UserForgotPassword from "@/components/users/UserForgotPassword.vue";
import UserResetPassword from "@/components/users/UserResetPassword.vue";
import Exhibitions from "@/components/exhibitions/Exhibitions.vue";
import NoExhibitionsForEvent from "@/components/exhibitions/NoExhibitionsForEvent.vue";
import ExhibitorStore from '@/components/exhibitorStore/ExhibitorStore.vue';
import {ExhibitorRoutes} from "@/components/exhibitorStore/ExhibitorRoutes";
import OrderPreview from "@/components/orders/OrderPreview.vue";
import OrderView from "@/components/orders/OrderView.vue";
import OrderPortalPay from "@/components/portal/OrderPortalPay.vue";
import UngerboeckPaymentsRedirect from "@/components/ungerboeck/UngerboeckPaymentsRedirect.vue";
import UngerboeckPaymentsCancel from "@/components/ungerboeck/UngerboeckPaymentsCancel.vue";
import NotFound from "@/components/common/NotFound.vue";

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{ name: 'default', path: '/', redirect: '/user' },
		{ name: 'user', path: '/user/:tenantId?/:exhibitionId?', component: User },
		{ name: 'login', path: '/login', component: UserLogin },
		{ name: 'registration', path: '/registration', component: UserRegistration },
		{ name: 'activate', path: '/activate/:activationToken', component: UserActivate },
		{ name: 'forgotPassword', path: '/forgotPassword', component: UserForgotPassword },
		{ name: 'reset', path: '/reset/:resetToken', component: UserResetPassword },
		{ name: 'exhibitions', path: '/exhibitions/:tenantId/:venueId?', component: Exhibitions },
		{ name: 'noExhibitions', path: '/noExhibitions/:tenantId/:venueId?', component: NoExhibitionsForEvent },
		{ path:"/order/:tenantId/:exhibitionId", redirect: {name:"exhibitorStoreCategory"}},
		{ path:"/exhibitorStore/:tenantId/:exhibitionId", name: "exhibitorStore",  component:ExhibitorStore, children: ExhibitorRoutes},
		{ name: 'orderPortalPay', path: '/order/pay/:orderId', component: OrderPortalPay },
		{ name: 'orderView', path: '/order/view/:tenantId?/:exhibitionId?/:orderId', component: OrderView },
		{ name: 'orderPreview', path: '/order/preview/:tenantId?/:exhibitionId?', component: OrderPreview },
		{ name: 'ungerboeckPaymentsRedirect', path: '/ubpayments/redirect', component: UngerboeckPaymentsRedirect },
		{ name: 'ungerboeckPaymentsCancel', path: '/ubpayments/cancel', component: UngerboeckPaymentsCancel },
		{ name: 'notFound', path: '/notfound', component: NotFound }
	],
});
