export interface NumberFilterConfig {
	locale: string;
	maximumFractionDigits: number;
}

let filterConfig: NumberFilterConfig = {
	locale: "en-US",
	maximumFractionDigits: 2
};

export function configure(config: NumberFilterConfig) {
	filterConfig = config;
}

export default function numberFilter(value: number = 0, useGrouping: boolean = true) {
	const formatter = new Intl.NumberFormat(filterConfig.locale, {
		style: 'decimal',
		maximumFractionDigits: filterConfig.maximumFractionDigits,
		useGrouping: useGrouping
	});

	return formatter.format(value);
}