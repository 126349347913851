import axios, { AxiosRequestConfig } from 'axios';
import { ExhibitionWebModel } from '../../models/generated/exhibitionWebModel';
import { ExhibitionPricesWebModel } from '../../models/generated/exhibitionPricesWebModel';

export const ExhibitionUrls = {
    isExhibitionAvailableForOrdering: '/api/Exhibition/isExhibitionAvailableForOrdering',
    get: '/api/Exhibition/get',
    getCurrentPrices: '/api/Exhibition/getCurrentPrices'
};

export class ExhibitionService {
    public static isExhibitionAvailableForOrdering(tenantId: string, exhibitionId: string, config: AxiosRequestConfig = {}): Promise<boolean> {
        config = {
            ...config,
            params: {
                tenantId,
                exhibitionId
            }
        };
        return axios.get<boolean>(ExhibitionUrls.isExhibitionAvailableForOrdering, config).then(x => x.data);
    }
    
    public static get(tenantId: string, exhibitionId: string, config: AxiosRequestConfig = {}): Promise<ExhibitionWebModel> {
        config = {
            ...config,
            params: {
                tenantId,
                exhibitionId
            }
        };
        return axios.get<ExhibitionWebModel>(ExhibitionUrls.get, config).then(x => x.data);
    }
    
    public static getCurrentPrices(tenantId: string, exhibitionId: string, config: AxiosRequestConfig = {}): Promise<ExhibitionPricesWebModel> {
        config = {
            ...config,
            params: {
                tenantId,
                exhibitionId
            }
        };
        return axios.get<ExhibitionPricesWebModel>(ExhibitionUrls.getCurrentPrices, config).then(x => x.data);
    }
    
}