<template>
	<div class="mainAppBarMobileFixed">
		<div class="mainAppBarMobileHeader">
			<div class="appBarMobileTitleA">{{ $t($tl.exhibitorStore) }}</div>
			<v-spacer/>
			<template v-if="currentUser">
				<v-menu offset-y>
					<template #activator="{ on }">
						<v-btn v-on="on" text color="primary">
							{{ currentUser.name }}
						</v-btn>
					</template>
					<v-list>
						<v-list-item :to="{ name: 'user' }">
							<v-list-item-content>
								<v-list-item-title>{{ $t($tl.home) }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item @click="signOut">
							<v-list-item-content>
								<v-list-item-title>{{ $t($tl.signOut) }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
			<template v-else>
				<v-btn text @click="goToLogin" color="primary"><v-icon small class="mr-1">mdi-account</v-icon>{{ $tc($tl.login) }}</v-btn>
			</template>
		</div>
		<div class="mainAppBarMobileExhibitorVenue">{{ exhibitionVenue.join(", ") }}</div>
		<div v-if="exhibitionName" class="mainAppBarMobileExhibitorInfo">
			<span class="exhibitionName">{{exhibitionName}}</span>
			<span> &bull; </span>
			<span class="exhibitionDates">{{ eventDate }}</span>
		</div>
	</div>
</template>

<script lang="ts">
import {Component, Watch, Vue, Prop, Emit} from "vue-property-decorator";
import {AuthService} from "@/services/generated";
import UserModule from "@/store/userModule";
import ExhibitorOrderModule from "@/store/exhibitorOrder";
import StoreConfigModule, {SliderNames, SubscriberAction} from "@/store/storeConfigModule";
import ExhibitorStoreModule from "@/store/exhibitorStoreModule";
import moment from 'moment';

@Component ({
	components: {}
})

export default class StoreToolbar extends Vue {

	//The route name from "router.ts" and "ExhibitorRoutes.ts"
	get showExhibitInformation() {
		return [
			"exhibitorStoreCategory",
			"exhibitorStoreSearch",
			"exhibitorStoreCart",
			"orderPreview",
			"orderView",
			"orderPortalPay"
		].includes(this.$route.name);
	}

	get exhibitionName() {
		return ExhibitorStoreModule.storeSettings.eventName;
	}

	get exhibitionVenue() {
		return ExhibitorStoreModule.storeSettings.venueNames;
	}

	get exhibitionStartDate() {
		return ExhibitorStoreModule.storeSettings.startDate;
	}

	get exhibitionEndDate() {
		return ExhibitorStoreModule.storeSettings.endDate;
	}

	get eventDate() {
		if (! this.exhibitionStartDate)
			return;
		
		const startDate =  moment(this.exhibitionStartDate).format( 'LL');
		const endDate = moment(this.exhibitionEndDate).format('LL');

		return  startDate === endDate ? startDate : startDate + " - " + endDate;
	}

	get currentUser() { return UserModule.user; }

	goToLogin() {
		StoreConfigModule.setSliderOpen(SliderNames.login)
	}

	async signOut() {
		await AuthService.logout();
		await UserModule.refreshUser();
	}
}
</script>

<style lang="scss">
@media print {
  .storeToolbar {
	display: none;
  }
}
  
  .mainAppBarMobileFixed {
	background-color: var(--v-grey-darken2);
	
	color:white;
	z-index: 8;
	position: sticky;
	top: 0;
	padding: 10px;
	display: flex;
	flex-direction: column;
	width:100%;
	
	.mainAppBarMobileHeader {
	  display: flex;
	  align-content: center;

	  .appBarMobileTitleA {
			line-height: 36px;
			font-size:18px;
			font-weight: 600;
	
	  }
	}
	.mainAppBarMobileExhibitorInfo, .mainAppBarMobileExhibitorVenue {
	  font-size: 12px;
	}
	
  }

</style>