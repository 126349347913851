<template>
    <div class="storePage" :class="{'mobileViewHeight': isMobile}">
        <slot></slot>
   </div>
</template>

<script lang="ts">
    import {Component, Vue} from "vue-property-decorator";
	import {detect} from 'detect-browser';
	import UserLoginSlider from "@/components/users/UserLoginSlider.vue";
	import StoreConfigModule from "@/store/storeConfigModule";
	@Component({})
	
	export default class StorePage extends Vue {
		get isMobile(){
			const browser = detect();
			return browser.os === 'iOS' || browser.os === 'Android OS';
		}	
	}
</script>

<style lang="scss">
    .storePage {
        display: flex;
        justify-content: center;
        //align-items: center;
        height: 100%;
		margin-top:48px;
    }

    @media print {
        .storePage {
            justify-content: center;
            align-items: start;
        }
    }
	@media only screen and (max-width: 768px) {
	  .v-application .v-toolbar {
		height: 64px!important;
	  }

	}
</style>