<template>
	<exhibitor-store-item-display 
			:store-item-list="searchItems" 
			:is-loading="isLoading" 
			:currency="currency"
			:no-items-msg="$tc($tl.noSearchResults)"
			show-category-instruction-on-items
	/>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import ExhibitorStoreItemDisplay from "@/components/exhibitorStore/common/ExhibitorStoreItemDisplay.vue";
import StoreConfigModule, {SubscriberAction} from "@/store/storeConfigModule";
import {ExhibitionService} from "@/services/generated";
import ExhibitorOrderModule from "@/store/exhibitorOrder";
import Enumerable from "linq";
import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";
import ExhibitorStoreModule from "@/store/exhibitorStoreModule";

@Component({
	components: {ExhibitorStoreItemDisplay}
})

export default class ExhibitorStoreSearchDisplay  extends Vue {
	isInitialized: boolean = true;
	tenantId: string = null;
	isExhibitionAvailable: boolean = false;
	exhibition = null ;
	exhibitionId: string = null;
	categoryId: string = null;
	currency: string = null;
	searchItems: string[]= []
	isLoading: boolean = false;
	unsubscribe = null;
	
	get storeSettings() {
		return ExhibitorStoreModule.storeSettings;
	}

	async created() {
		this.currency = this.storeSettings.currency;
		await this.refreshSearchResults();
		this.unsubscribe = ExhibitorStoreHelper.subscribeToStore(this.subscriptionAction)
	}
	
	subscriptionAction(action) {
		if (action.type !== "StoreConfigModule/subscriberEvent")
			return;

		if (action.payload === SubscriberAction.search_updated)
			this.refreshSearchResults();
	}

	beforeDestroy() {
		this.unsubscribe();
	}
	
	async refreshSearchResults() {
		const search = ExhibitorStoreModule.searchText;
		
		this.searchItems = [];

		if (!search || search.length === 0)
			return;

		this.isLoading = true;
		
		this.searchItems = ExhibitorStoreHelper.getStoreItemIdsBySearch(search);
		
		this.isLoading = false;
		
	}
}

interface StoreItem {
	name: string
}
</script>