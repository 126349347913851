<template>
    <store-page>
        <v-container v-if="isInitialized">
            <v-row justify="center">
                <v-col md="6">
                    <v-card>
                        <v-card-title>
                            <vops-header :label="order.exhibition.name" :sublabel="order.user.organization">
                                <v-btn class="orderView-print" icon small color="primary" @click="print">
                                    <v-icon>print</v-icon>
                                </v-btn>
                            </vops-header>
                        </v-card-title>
                        <v-card-text>
                            <v-divider/>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>#{{ order.uniqueId }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ $t($tl.orders.orderNumber) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ order.exhibition.venueNames.join(', ') }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ $t($tl.venue) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="order.booth">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ order.booth }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ $t($tl.booth) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ order.grandTotal | money(order.currency) }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ $t($tl.orders.totalPaid) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ order.paidOn | moment('L LT') }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ $t($tl.orders.paidOn) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ order.paymentReference }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ $t($tl.payments.paymentReference) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-subheader>{{ $tc($tl.item, 2) }}</v-subheader>
                            <v-simple-table>
                                <thead>
                                <tr>
									<th>{{ $t($tl.name) }}</th>
                                    <th class="text-center">{{ $t($tl.orders.quantity)}}</th>
                                    <th v-if="hasTimeBoundItems" class="text-center">{{ $t($tl.orders.timeQuantity)}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in order.items" :key="item.itemId">
                                   <td>
                                        {{ item.name }} - {{ item.unitName }} <span v-if="item.instruction"> - {{item.instruction}}</span>
                                    </td>
									<td class="text-center">{{ item.quantity }}</td>
									<td class="text-center" v-if="hasTimeBoundItems">
										<span v-if="item.timeUnit !== null">{{item.timeQuantity}} {{getTimeQuantityLabel(item.timeUnit, item.timeQuantity)}}</span>
									</td>
                                </tr>
                                </tbody>
                            </v-simple-table>

                            <v-subheader>{{ $tc($tl.attachment, 2) }}</v-subheader>
                            <div class="ml-1" v-for="attachment in order.attachments" :key="attachment.id">
                                <v-btn text small color="primary" @click="download(attachment)">{{ attachment.name }}</v-btn>
                            </div>
                            <v-container class="font-italic" v-if="order.attachments.length === 0">
                                {{ $tc($tl.attachment, 0) }}
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </store-page>
</template>

<script lang="ts">
	import {Component, Vue} from "vue-property-decorator";
    import {OrderAttachmentModel, OrderModel} from "@/models/generated";
    import {OrderService} from "@/services/generated";
    import StorePage from "@/components/common/StorePage.vue";
    import FileDownloader from "@/services/fileDownloader";
    import StoreConfigModule from "@/store/storeConfigModule";
	import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";
    
	@Component({
        components: {StorePage}
    })
	export default class OrderView extends Vue {
	    order: OrderModel = null;
	    isInitialized: boolean = false;
		hasTimeBoundItems: boolean = true;
		
	    async created() {
	        const orderId = this.$route.params.orderId;
	        this.order = await OrderService.get(orderId);
			this.hasTimeBoundItems = this.order.items.some(x => x.timeUnit !== null);
	        await StoreConfigModule.clearConfig();
	        this.isInitialized = true;
        }

		getTimeQuantityLabel(timeUnit:number, quantity: number) {
			return ExhibitorStoreHelper.getTimeQuantityLabel(timeUnit, quantity)
		}
		
        print() {
	        window.print();
        }

        async download(attachment: OrderAttachmentModel) {
            const file = await OrderService.download(this.order.id, attachment.id);
            await FileDownloader.download(file, attachment.name);
        }
	}
</script>

<style lang="scss">
    @media print {
        .orderView-print {
            display: none;
        }
    }
</style>