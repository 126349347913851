import FileSaver from "file-saver";
import {lookup} from "mime-types";

export default class FileDownloader {
    public static download(buffer: ArrayBuffer, fileName: string, mimeType: string = null) {
        if (!mimeType) {
            const lookupResult = lookup(fileName);
            mimeType = lookupResult !== false ? lookupResult : 'application/pdf';
        }
        const blob = new Blob([buffer], {type: mimeType});
        FileSaver.saveAs(blob, fileName);
    }
}