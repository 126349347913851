import axios, { AxiosRequestConfig } from 'axios';
import { PaymentGatewayWebModel } from '../../models/generated/paymentGatewayWebModel';

export const PaymentGatewayUrls = {
    get: '/api/PaymentGateway/get'
};

export class PaymentGatewayService {
    public static get(tenantId: string, config: AxiosRequestConfig = {}): Promise<PaymentGatewayWebModel> {
        config = {
            ...config,
            params: {
                tenantId
            }
        };
        return axios.get<PaymentGatewayWebModel>(PaymentGatewayUrls.get, config).then(x => x.data);
    }
    
}