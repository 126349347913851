<template>
    <div class="formAddress" :class="{'focused': isFocused}">
        <v-text-field class="formAddress-line1" @focus="focused" @blur="blurred" :label="$t($tl.address.address)"
                      v-model="address.street" @input="input" hide-details :placeholder="$t($tl.address.streetHint)"
					  :rules="[$rules.required]"/>
        <v-text-field v-model="address.unit" @focus="focused" @blur="blurred" @input="input" hide-details
                      :placeholder="$t($tl.address.unitHint)"/>
        <v-row dense>
            <v-col cols="5">
                <v-text-field :placeholder="$t($tl.address.city)" @focus="focused" @blur="blurred"
                              v-model="address.locality" @input="input" hide-details :rules="[$rules.required]"/>
            </v-col>
            <v-col cols="4">
                <v-text-field :placeholder="$t($tl.address.state)" @focus="focused" @blur="blurred"
                              v-model="address.region" @input="input" hide-details :rules="[$rules.required]"/>
            </v-col>
            <v-col cols="3">
                <v-text-field :placeholder="$t($tl.address.zip)" @focus="focused" @blur="blurred"
                              v-model="address.postalCode" @input="input" hide-details :rules="[$rules.required]"/>
            </v-col>
        </v-row>
        <v-text-field :placeholder="$t($tl.address.country)" @focus="focused" @blur="blurred" v-model="address.country"
                      @input="input" hide-details :rules="[$rules.required]"/>
    </div>
</template>

<script lang="ts">
    import {Component, Vue, Prop, Emit, Watch} from "vue-property-decorator";

    @Component
    export default class FormAddress extends Vue {
        @Prop() value: Address;

        address: Address = null;
        isFocused: boolean = false;

        @Watch("value", {immediate: true})
        onAddressChanged() {
            this.address = this.value;
        }

        @Emit()
        input() {
            return this.address;
        }

        focused() {
            this.isFocused = true;
        }

        blurred() {
            this.isFocused = false;
        }
    }

    export interface Address {
        street: string;
        unit: string;
        locality: string;
        region: string;
        postalCode: string;
        country: string;
    }
</script>

<style lang="scss">
    .formAddress {
        padding-bottom: 16px;
    }

    .v-label {
        .formAddress.focused .formAddress-line1 & {
            color: var(--v-primary-base);
            caret-color: var(--v-primary-base);
        }
    }
</style>