import Axios, { AxiosError } from "axios";
import AxiosMessageModule from '../../store/axiosMessageModule';
import i18n, { $lang } from '@/config/plugins/i18n';

export default class AxiosConfig {
	static bootstrap() {
		Axios.interceptors.request.use(config => {
			const vopsClient = process.env.VUE_APP_VERSION;
			config.headers["Vops-Client"] = vopsClient;
			return config;
		});

		Axios.interceptors.response.use(response => response, (error: AxiosError) => {
			if (error.response.status === 409)
				AxiosMessageModule.updateRefresh(true);

			if (error.response.status === 500)
				AxiosMessageModule.updateError(i18n.tc($lang.internalServerError));
				
			return Promise.reject(error.response);
		});
	}
}