<template>
	<vops-form @submit="submit()" v-if="isInitialized">
		<vops-slider-pane @cancel="close()">
			<template #label>
				<span>
					<v-icon color="success" x-large class="mr-2">check</v-icon> {{ $tc($tl.emailSent) }}
				</span>
			</template>
				<v-list>
					<v-list-item>
						<v-list-item-title>
							{{ $t($tl.emailSentToValue, { value: this.email }) }}
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-list-item-content>
							{{ $t($tl.reset.clickLinkToReset) }}
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-content>
							{{ $t($tl.checkJunk) }}
						</v-list-item-content>

					</v-list-item>
				</v-list>
			<template  #footer>
				<div class="sliderPane-footer-content px-6 py-3">
					<v-btn class="sliderPane-footer-content" color="primary" type="submit" >
						{{$t($tl.login)}}
					</v-btn>
					<v-btn class="sliderPane-footer-content ml-4" @click="close">
						{{ $tc($tl.cancel) }}
					</v-btn>
				</div>
			</template>
		</vops-slider-pane>
	</vops-form>
</template>


<script lang="ts">
import {Component, Emit, Vue, Prop, Watch} from "vue-property-decorator";

@Component({
	components: {}
})
export default class UserResetEmailSent extends Vue {
	@Prop() email:string;
	
	@Emit() 
	close(){};
	
	@Emit() 
	submit(){return this.email};
	
	isInitialized: boolean = true;
}
</script>