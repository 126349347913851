import axios, { AxiosRequestConfig } from 'axios';
import { CreateUserModel } from '../../models/generated/createUserModel';
import { RequestPasswordResetModel } from '../../models/generated/requestPasswordResetModel';
import { ResetPasswordModel } from '../../models/generated/resetPasswordModel';
import { ActivateUserModel } from '../../models/generated/activateUserModel';

export const UserUrls = {
    isValidEmail: '/api/User/isValidEmail',
    createUser: '/api/User/createUser',
    requestPasswordReset: '/api/User/requestPasswordReset',
    resetPassword: '/api/User/resetPassword',
    activateUser: '/api/User/activateUser'
};

export class UserService {
    public static isValidEmail(email: string, config: AxiosRequestConfig = {}): Promise<boolean> {
        config = {
            ...config,
            params: {
                email
            }
        };
        return axios.get<boolean>(UserUrls.isValidEmail, config).then(x => x.data);
    }
    
    public static createUser(model: CreateUserModel, config: AxiosRequestConfig = {}): Promise<string> {
        config = {
            ...config,
        };
        const _data = model;
        return axios.post<string>(UserUrls.createUser, _data, config).then(x => x.data);
    }
    
    public static requestPasswordReset(model: RequestPasswordResetModel, config: AxiosRequestConfig = {}): Promise<ArrayBuffer> {
        config = {
            responseType: 'arraybuffer',
            ...config,
        };
        const _data = model;
        return axios.post<ArrayBuffer>(UserUrls.requestPasswordReset, _data, config).then(x => x.data);
    }
    
    public static resetPassword(model: ResetPasswordModel, config: AxiosRequestConfig = {}): Promise<ArrayBuffer> {
        config = {
            responseType: 'arraybuffer',
            ...config,
        };
        const _data = model;
        return axios.post<ArrayBuffer>(UserUrls.resetPassword, _data, config).then(x => x.data);
    }
    
    public static activateUser(model: ActivateUserModel, config: AxiosRequestConfig = {}): Promise<ArrayBuffer> {
        config = {
            responseType: 'arraybuffer',
            ...config,
        };
        const _data = model;
        return axios.post<ArrayBuffer>(UserUrls.activateUser, _data, config).then(x => x.data);
    }
    
}