<template>
	<div>
		<div elevation="0" class="text-uppercase exhibitionDepts">{{ $tc($tl.department, 2) }}</div>
		<v-divider class="categoryMenuDivider" />
		<v-expansion-panels v-model="panel" accordion focusable hover class="exhibitor-store">
			<v-expansion-panel v-for="dep in departmentList" class="text-uppercase">
				<v-expansion-panel-header class="text-uppercase">{{dep.name}}</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div v-for="cat in dep.categories" class="dept-cat">
						<router-link class="nav-link" :to="{ name: 'exhibitorStoreCategory', params: { tenantId: tenantId, exhibitionId: exhibitionId, categoryId: cat.id } }">
							{{ cat.name }}
						</router-link>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import StoreConfigModule from "@/store/storeConfigModule";
import ExhibitorStoreModule from "@/store/exhibitorStoreModule";
import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";

@Component({
	components: {}
})

export default class ExhibitorStoreCategoryMenu extends Vue {
	isInitialized: boolean = true;
	tenantId: string = null;
	exhibitionId: string = null;
	panel: number = 0;
	

	get storeSettings() {
		return ExhibitorStoreModule.storeSettings;
	}
   
	
	
	
	
	async created() {
		this.tenantId = this.storeSettings.tenantId;
		this.exhibitionId = this.storeSettings.exhibitionId;
		
		if (this.$route.params.categoryId) {
			const catId = this.$route.params.categoryId;
			this.panel = Math.max(0, this.departmentList.findIndex(x => x.categories.some(x => x.id === catId)));
		}
	}
	
	get departmentList() {
		return  ExhibitorStoreModule.departmentInfo;
	}

	get storeConfig() {
		return StoreConfigModule.storeConfig;
	}
	
}

</script>

<style lang="scss">

  .exhibitor-store {
	overflow: auto;
	border-radius: 0px!important;
	.nav-link {
	  text-decoration: none;
	  color: var(--v-grey-lighten2);
	  padding-bottom: 10px;
	}

	.nav-link:hover {
	  opacity: .5;
	}

	.router-link-exact-active {
	  color: orange;
	  font-weight: 600;
	}

	.v-expansion-panels {
	  overflow: auto;
	}
	
	.v-expansion-panel-header:before {
	  border-radius: 0px!important;
	}

	.v-expansion-panel-header--active {
	  color: white;
	  font-weight: bold;
	}

	.dept-cat {
	  margin: 10px 0 10px 0;
	  padding-left: 20px;
	}

	.v-expansion-panel-content__wrap {
	  padding: 0 3px 1px !important;
	}

	.v-expansion-panel--active > .v-expansion-panel-header {
	  min-height: 48px !important;
	}
  }

  .categoryMenuDivider {
	border-color: var(--v-grey-darken2) !important;
  }
  
  .exhibitionDepts {
	height: 55px !important;
	display: flex;
	font-size: 1.2rem;
	line-height: .7;
	min-height: 55px;
	outline: none;
	padding: 20px 24px;
	position: relative;
	color: var(--v-grey-darken-2);
	font-weight: bold;
	background-color: #fff;
	border-top: orange 1px solid !important;
	//border-right: grey 1px solid;
	box-shadow: -1px 0px 3px inset grey;
  }
  .exhibitor-store {
	background: var(--v-grey-darken-2)!important;
  }

  .exhibitionBack {
	justify-content: left;
	background-color: #272727 !important;
	height: 48px !important;

	span {
	  margin-left: 20px;
	}
  }

</style>
