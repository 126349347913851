import moment from 'moment';
import 'twix';
import { TwixFormatOptions } from 'twix';

export interface IDateRangeFilterOptions extends TwixFormatOptions {
	allDay?: boolean;
}

function getMoment(value: string | Date) {
	return moment(value, moment.ISO_8601);
}

export default function dateRangeFilter(start: string, end: string, options: IDateRangeFilterOptions = {}) {
	if (start == null)
		return null;

	if(end == null)
		end = start;

	var mStart = getMoment(start);
	var mEnd = getMoment(end);
	if (mStart.isSame(mEnd))
		mEnd = mEnd.add(1, 'millisecond'); // issue in twix

	return mStart.twix(mEnd, options.allDay).format(options);
}