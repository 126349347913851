import ExhibitorStoreModule from "@/store/exhibitorStoreModule";
import {ExhibitionService} from "@/services/generated";
import store from "@/config/plugins/store";
import StoreConfigModule, {SliderNames, SubscriberAction} from "@/store/storeConfigModule";
import {Order, OrderItem} from "@/store/exhibitorOrder";
import exhibitorStoreModule from "@/store/exhibitorStoreModule";
import i18n, {$lang} from "@/config/plugins/i18n";

export default class ExhibitorStoreHelper {
	static subscribeToStore(handler) {
		return store.subscribeAction(handler);
	};
	
	static noItemImageUrl() {
		return "/images/noImage.png";
	}
	
	static getTimeQuantity(isTimeBound: boolean, timeQuantity: number) {
		if (! isTimeBound)
			return 1;
		
		const tQ = timeQuantity ? timeQuantity : 1;
		return Math.max(1, tQ );
	}
	
	static async userLoggedIn(userId:string) {
		exhibitorStoreModule.setUserId(userId);
		if (exhibitorStoreModule.storeSettings.tenantId === null || exhibitorStoreModule.storeSettings.exhibitionId === null)
			return;
		
		exhibitorStoreModule.restoreUserCartForExhibition(exhibitorStoreModule.storeSettings.cartKey);
		this.refreshTotalPurchaseAmount();
		StoreConfigModule.subscriberEvent(SubscriberAction.store_items_updated);
	}
	
	static async configureStore(tenantId:string, exhibitionId:string, userId:string) {
		if (! await ExhibitionService.isExhibitionAvailableForOrdering(tenantId, exhibitionId))
			return;
		
		const currentSettings = ExhibitorStoreModule.storeSettings;
		
		if (currentSettings.tenantId === tenantId && currentSettings.exhibitionId === exhibitionId && currentSettings.userId === userId)
			return;
		
		const exhibition = await ExhibitionService.get(tenantId, exhibitionId);
		
		const storeSettings: StoreSettings = {
			tenantId: tenantId,
			exhibitionId: exhibitionId,
			userId: userId,
			cartKey: `Cart-${tenantId}_${exhibitionId}_${userId}`,
			currency: 'USD',
			exhibitionInstruction: exhibition.storeInstructions,
			eventName: exhibition.eventName,
			priceScheduleId: null,
			venueNames: [...exhibition.venueNames],
			startDate: exhibition.startDate,
			endDate: exhibition.endDate,
			isBoothRequired: exhibition.isBoothNumberRequired,
			displayItemImages: false
		}
		
		ExhibitorStoreModule.setStoreSettings(storeSettings);
		await ExhibitorStoreHelper.loadPriceScheduleItems();
		this.restoreUserCart();
	}
	
	static async restoreUserCart() {
		this.clearCart();
		if (!ExhibitorStoreModule.storeSettings.userId)
			return;
		
		ExhibitorStoreModule.restoreUserCartForExhibition(ExhibitorStoreModule.storeSettings.cartKey);
		this.refreshTotalPurchaseAmount();
		StoreConfigModule.subscriberEvent(SubscriberAction.store_items_updated);
		StoreConfigModule.subscriberEvent(SubscriberAction.cart_total_updated);
	}
	
	static async loadPriceScheduleItems() {
		const tenantId:string = ExhibitorStoreModule.storeSettings.tenantId;
		const exhibitionId: string = ExhibitorStoreModule.storeSettings.exhibitionId;
		const priceSchedule = await ExhibitionService.getCurrentPrices(tenantId, exhibitionId);
		
		ExhibitorStoreModule.clearStoreItems();
		
		if (!priceSchedule) 
			return;
		
		ExhibitorStoreModule.setCurrency(priceSchedule.currency);
		ExhibitorStoreModule.setPriceScheduleId(priceSchedule.priceScheduleId);
		
		const items = priceSchedule.items.sort((itemA, itemB) => {
			
			const deptSort = this.getSort(itemA.departmentSortOrder, itemB.departmentSortOrder, 1000); 
			
			if (deptSort !== 0) 
				return deptSort;

			const catSort = this.getSort(itemA.categorySortOrder, itemB.categorySortOrder, 1000);;
			if (catSort !== 0)
				return catSort;


			const itemSort = this.getSort(itemA.itemSortOrder, itemB.itemSortOrder, 1000);
			if (itemSort !==0)
				return itemSort;
			
			const depSortChar = (itemA.departmentName || "ZZZZZZZ").localeCompare((itemB.departmentName || "ZZZZZZZ"));
			if (depSortChar !==0)
				return depSortChar;

			const catSortChar = itemA.categoryName.localeCompare(itemB.categoryName);
			if (catSortChar !==0)
				return catSortChar;

			return itemA.name.localeCompare(itemB.name);

		})
		
		const displayImages = items.some(x => {return x.imageUrl || x.categoryImageUrl});
		items.forEach(item => {
			ExhibitorStoreModule.updateStoreItem(this.convertToStoreBase(item));
		})

		ExhibitorStoreModule.setDisplayImages(displayImages);
		
		this.refreshDepartmentInfo();
	}
	
	static  fixSort(sortNumber:number, defaultSort: number ) {
		return sortNumber === null ? defaultSort : sortNumber;
    }
	
	static getSort(sortA:number, sortB:number, defaultSort:number) {
		return this.fixSort(sortA, defaultSort ) - this.fixSort(sortB, defaultSort);
	}
	
	static async restoreOrder(tenantId:string, exhibitionId:string, userId:string, storeItemData: StoreItemData[]) 
	{
		ExhibitorStoreModule.clearStoreSettings();
		await this.configureStore(tenantId, exhibitionId, userId);
		ExhibitorStoreModule.clearSavedUserCartForExhibition(ExhibitorStoreModule.storeSettings.cartKey);
		ExhibitorStoreModule.clearCart();
		
		storeItemData.forEach(storeItemDta => {
			ExhibitorStoreModule.updateCartItem(storeItemDta);
		})
		
		this.refreshTotalPurchaseAmount();
	}
	
	static convertToStoreBase(item:any):StoreItemBase {
		const storeItem:StoreItemBase = {
			id: item.id,
			name: item.name,
			description: item.description ? item.description:"",
			departmentId: item.departmentId || "department-0",
			departmentName: item.departmentId ? item.departmentName : "General",
			categoryId: item.categoryId,
			categoryName: item.categoryName,
			categoryExhibitionInstruction: item.categoryExhibitionInstruction,
			price: item.price,
			imageUrl: item.imageUrl || item.categoryImageUrl || this.noItemImageUrl,
			unitName: item.unitName,
			isTimeBound: item.isTimeBound,
			timeUnit: item.timeUnit,
			timeUnitName: item.isTimeBound ? (item.timeUnit === 1 ? "Day" : "Hour") : "",
			defaultTimeQuantity: this.getTimeQuantity(item.isTimeBound, item.defaultTimeQuantity)
		};
		
		return storeItem;
	}
	
	
	static refreshDepartmentInfo() {
		let departmentInfoList:DepartmentInfo[] = [];
		const departmentIndex: string[] = [];
		const categoryIndex: string[] = [];
		const storeItems = ExhibitorStoreModule.storeItems;
		storeItems.forEach(item => {
			if (categoryIndex.includes(item.categoryId))
				return;
		
			categoryIndex.push(item.categoryId);
		
			const depIndex = departmentIndex.indexOf(item.departmentId);
			
			if (depIndex >= 0) {
				departmentInfoList[depIndex].categories.push({
					id:item.categoryId, 
					name:item.categoryName, 
					exhibitorInstruction: item.categoryExhibitionInstruction,
					instructionSeen: false
					});
			} else {
				departmentIndex.push(item.departmentId);
				departmentInfoList.push({
					id:item.departmentId,
					name:item.departmentName,
					categories: [{
						id:item.categoryId, 
						name:item.categoryName, 
						exhibitorInstruction: item.categoryExhibitionInstruction,
						instructionSeen: false
					}]
				})
			}
		})
		
		ExhibitorStoreModule.setDepartmentInfo(departmentInfoList);
	}
	
	static getStoreItemIdsByCategoryId(categoryId:string) {
		return ExhibitorStoreModule.storeItems.filter(item => item.categoryId === categoryId).map(item => item.id);
	}

	static getStoreItemIdsBySearch(search:string) {
		const searchP = search.toLowerCase();
		return ExhibitorStoreModule.storeItems.filter(item => item.name.toLowerCase().includes(searchP) || item.description.toLowerCase().includes(searchP)).map(item=>item.id);
	}

	static getStoreItemIdsInCart() {
		return ExhibitorStoreModule.storeItems.filter(item => item.isInCart).map(item=>item.id);
	}
	
	static getStoreItemsByList(storeItemsList:string[]) {
		return ExhibitorStoreModule.storeItems.filter(item => storeItemsList.includes(item.id));
	}

	static openAddToCart(storeItemId: string) {
		ExhibitorStoreModule.setCurrentItemId(storeItemId);
		StoreConfigModule.setSliderOpen(SliderNames.addToCart);
		StoreConfigModule.setSliderClosed(SliderNames.editCartItem);
		StoreConfigModule.subscriberEvent(SubscriberAction.add_item_to_cart);
	}

	static openEditCartItem(storeItemId: string) {
		ExhibitorStoreModule.setCurrentItemId(storeItemId);
		StoreConfigModule.setSliderOpen(SliderNames.editCartItem);
		StoreConfigModule.setSliderClosed(SliderNames.addToCart);
		StoreConfigModule.subscriberEvent(SubscriberAction.edit_item_in_cart);
	}
	
	static clearCart() {
		ExhibitorStoreModule.clearCart();
		this.refreshTotalPurchaseAmount();
		StoreConfigModule.subscriberEvent(SubscriberAction.store_items_updated);
	}
	
	static updateCart(storeItemData:StoreItemData) {
		ExhibitorStoreModule.updateCartItem(storeItemData);
		ExhibitorStoreModule.saveUserCartForExhibition(ExhibitorStoreModule.storeSettings.cartKey);
		
		this.refreshTotalPurchaseAmount();
		StoreConfigModule.subscriberEvent(SubscriberAction.store_items_updated);
	}
	
	static removeFromCart(storeItemId: string) {
		ExhibitorStoreModule.removeFromCart(storeItemId);
		ExhibitorStoreModule.saveUserCartForExhibition(ExhibitorStoreModule.storeSettings.cartKey);

		this.refreshTotalPurchaseAmount();
		StoreConfigModule.subscriberEvent(SubscriberAction.store_items_updated);
	}

	static refreshTotalPurchaseAmount() {
		let money = 0;
		let totalQuantity = 0;
		
		const cartItems = ExhibitorStoreModule.storeItems.filter(x => x.isInCart);
		cartItems.forEach(item => {
			let price = item.price * item.quantity * this.getTimeQuantity(item.isTimeBound, item.timeQuantity);
			money += price;
			totalQuantity += <number>item.quantity;
		})
		
		ExhibitorStoreModule.setTotalPurchaseAmount(money);
		ExhibitorStoreModule.setCartItemTotal(totalQuantity);
		StoreConfigModule.subscriberEvent(SubscriberAction.cart_total_updated);
	}
	
	static setSearchText(search:string) {
		ExhibitorStoreModule.setSearchText(search);
	}
	
	static clearSearchText() {
		ExhibitorStoreModule.clearSearchText();
	}
	
	static categoryInstructionSeen(categoryId:string) {
		ExhibitorStoreModule.setCategoryInstructionSeen(categoryId);
	}
	
	static sawStoreWideInstruction() {
		ExhibitorStoreModule.setSawStoreWideInstruction();
	}
	
	static resetStore() {
		ExhibitorStoreModule.clearStoreSettings();
		ExhibitorStoreModule.clearStoreItems();
		this.refreshTotalPurchaseAmount();
	}
	
	static generateStoreOrder() {
		const itemList = this.getStoreItemIdsInCart();
		const storeItems = this.getStoreItemsByList(itemList);
		const storeSettings = ExhibitorStoreModule.storeSettings;
		
		const items: OrderItem[] =  storeItems.map(item => {
			return {
				id: item.id,
				quantity: item.quantity,
				timeQuantity: this.getTimeQuantity(item.isTimeBound, item.timeQuantity),
				name: item.name,
				unit: item.unitName,
				timeUnit: item.timeUnit,
				categoryId: item.categoryId,
				categoryName: item.categoryName,
				instruction: item.instruction,
				isTimeBound: item.isTimeBound
			}
		})
		
		return {
			id: null,
			tenantId: storeSettings.tenantId,
			booth: ExhibitorStoreModule.booth,
			onsiteContact: ExhibitorStoreModule.onsiteContact,
			exhibitionId: storeSettings.exhibitionId,
			exhibitionName: storeSettings.eventName,
			venueNames: [...storeSettings.venueNames],
			priceScheduleId: storeSettings.priceScheduleId,
			currency: storeSettings.currency,
			items: items,
			attachments: []
		};
	}
	
	static getTimeQuantityLabel(timeUnit:number, quantity: number) {
		if (timeUnit === null || quantity === null)
			return "";
		
		const plurization = quantity > 1 ? 2 : 1;
		
		if (timeUnit === TimeUnit.Hourly)
			return i18n.tc($lang.hour, plurization);


		if (timeUnit === TimeUnit.Daily)
			return i18n.tc($lang.day, plurization);

		return "";
	}
}

/**** Shared Models ****/

export interface StoreItemBase {
	id: string,
	name: string,
	departmentName: string,
	departmentId: string,
	categoryId: string,
	categoryName: string,
	categoryExhibitionInstruction?: string,
	description: string,
	price: number,
	imageUrl: string,
	unitName: string,
	isTimeBound: boolean,
	timeUnit: number,
	timeUnitName: string,
	defaultTimeQuantity: number
}

export interface StoreItemData {
	id:string,
	instruction?:string,
	quantity: number,
	timeQuantity: number,
	isInCart: boolean
}

export interface StoreItem extends StoreItemBase, StoreItemData {}

export interface StoreSettings {
	tenantId: string
	exhibitionId: string,
	userId: string,
	cartKey: string,
	eventName: string,
	currency: string,
	exhibitionInstruction: string,
	priceScheduleId: string,
	venueNames: string[],
	startDate: string,
	endDate: string,
	isBoothRequired: boolean,
	displayItemImages: boolean
}

export interface DepartmentInfo {
	id: string,
	name: string,
	categories : CategoryInfo[],
}

export interface CategoryInfo {
	id: string,
	name: string,
	exhibitorInstruction?: string,
	instructionSeen: boolean
}

export interface CartUpdate {
	id: string,
	quantity: number,
	timeQuantity: number,
	instruction: string
}

enum TimeUnit {
	Hourly = 0,
	Daily = 1
}