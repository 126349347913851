import Vue from "vue";
import * as MoneyFilter from "@/filters/moneyFilter";
import dateRangeFilter  from "@/filters/dateRangeFilter";

export default class FiltersConfig {
    static bootstrap(locale: string, maximumFractionDigits: number) {
		Vue.filter('dateRange', dateRangeFilter);
        MoneyFilter.configure({
            locale: locale,
            maximumFractionDigits: maximumFractionDigits
        });
		
    }
}