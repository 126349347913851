<template>
	<v-col cols="auto">
	<v-card class="storeItem rounded-b-0 rounded-t-lg" :class="{noImage:!displayImage}" >
		<v-img v-if="displayImage"
				class="itemImage"
				:src="fullImagePath"
			   @error="imageError()"
		>
			<template v-if="storeItem.isInCart">
				<div class="inCartDisplay">
					<v-chip label small :title="$tc($tl.orders.quantity)"> {{displayQuantity()}} </v-chip>
				</div>
			</template>
		</v-img>
		<v-card-title class="itemTitle" :title="storeItem.name">
			<span class="itemTitleText">{{storeItem.name}}</span>
			<template v-if="showCategoryWarning && storeItem.categoryExhibitionInstruction && storeItem.categoryExhibitionInstruction.length > 0">
				<v-spacer/>	
				<v-icon class="mr-1 itemInstruction" color="primary lighten-1" :title="storeItem.categoryExhibitionInstruction">mdi-information-outline</v-icon>
			</template>
		</v-card-title>
		<v-card-text v-if="! displayImage">
				<div class="inCartDisplay" >
					<v-chip v-if="storeItem.isInCart" label small :title="$tc($tl.orders.quantity)"> {{displayQuantity()}} </v-chip>
				</div>
		</v-card-text>
		<v-card-text :title="storeItem.description" class="text-sm itemDescription">
			{{ description }}
		</v-card-text>
		<v-card-title class="itemPrice">
			<span>{{ $tc($tl.orders.price) }}: {{ storeItem.price | money(currency) }}</span>
			
		</v-card-title>
		<v-card-subtitle>
			<span class="priceUnit">{{unitNames}}</span>
		</v-card-subtitle>

		<v-divider/>
		<v-card-actions>
			<template v-if="!storeItem.isInCart">
				<v-btn v-if="!storeItem.isInCart" small color="primary lighten-1" class="ml-2"  @click="addToCart">
					<v-icon	right dark>mdi-cart-plus</v-icon>
					{{ $tc($tl.addToCart) }} 
				</v-btn>
				<v-spacer/>
				<v-btn  :title="$tc($tl.quickAdd)" class="mr-2" small color="primary lighten-1" @click="quickAdd">
					<v-icon dark>mdi-numeric-positive-1</v-icon>
				</v-btn>
			</template>
			<template v-else>
				<v-btn class="ml-2" small color="primary lighten-1" @click="editCartItem">
					<v-icon	right dark>mdi-cart-outline</v-icon>
					{{ $tc($tl.editItem) }}
				</v-btn>
				<v-spacer/>
				<v-btn class="mr-2" small text color="error" @click="removeFromCart">
					{{ $tc($tl.remove) }}
				</v-btn>
			</template>
		</v-card-actions>
	</v-card>
	</v-col>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ExhibitorStoreHelper, * as ESH from "@/services/exhibitorStoreHelper";
import ExhibitorStoreModule from "@/store/exhibitorStoreModule";
import StoreConfigModule, {SliderNames, SubscriberAction} from "@/store/storeConfigModule";
import UserModule from "@/store/userModule";

@Component({
	components: {}
})

export default class exhibitorStoreItem extends Vue {
	@Prop() storeItemId;
	@Prop() currency;
	@Prop({type:Boolean, default:false}) showCategoryWarning;
	
	storeIndex: number = 0;
	storeItem:ESH.StoreItem = null;
	unsubscribe = null;
	isInitialized: boolean = true;
	useImage:string = null;
	
	created() {
		this.unsubscribe = ExhibitorStoreHelper.subscribeToStore(this.subscriptionAction)
		this.getStoreIndex();
		this.refreshStoreItem();
	}

	get description() {
		return this.storeItem.description.length > 165 ? this.storeItem.description.substring(0,165) + " ..." : this.storeItem.description;
	}
	
	imageError() {
		if (this.useImage !== ExhibitorStoreHelper.noItemImageUrl())
			this.useImage = ExhibitorStoreHelper.noItemImageUrl();
	}
	
	displayQuantity() {
		if (!this.storeItem.isTimeBound)
				return (`${this.storeItem.quantity} ${this.$tc(this.$tl.inCart)}`).toLowerCase();
	
		const quantity = this.storeItem.unitName.replace("per", "").replace("Per","");
	
		return (`${this.storeItem.quantity} ${quantity}${this.storeItem.quantity > 1 ? "s":""} For ${this.storeItem.timeQuantity} ${this.timeQuantityLabel}`).toLowerCase();
	}
	
	subscriptionAction(action) {
		if (action.type !== "StoreConfigModule/subscriberEvent")
			return;
		
		if (action.payload === SubscriberAction.store_items_updated)
			this.refreshStoreItem();
	}

	beforeDestroy() {
		this.unsubscribe();
	}

	@Watch('storeItemId')
	getStoreIndex() {
		this.storeIndex = ExhibitorStoreModule.storeItems.findIndex(x => x.id === this.storeItemId);
	}
	
	refreshStoreItem()
	{
		this.storeItem = ExhibitorStoreModule.storeItems[this.storeIndex];
		this.useImage = this.storeItem.imageUrl;
	}

	quickAdd () {
		if (!UserModule.user) {
			StoreConfigModule.setSliderOpen(SliderNames.login);
			return;
		}
		
		ExhibitorStoreHelper.updateCart({id: this.storeItem.id, quantity:1, timeQuantity: ExhibitorStoreHelper.getTimeQuantity(this.storeItem.isTimeBound, this.storeItem.defaultTimeQuantity), instruction:null, isInCart:true});
	}
	
	addToCart() {
		if (!UserModule.user) {
			StoreConfigModule.setSliderOpen(SliderNames.login);
			return;
		}
		
		ExhibitorStoreHelper.openAddToCart(this.storeItem.id);
	}
	
	editCartItem() {
		if (!UserModule.user) {
			StoreConfigModule.setSliderOpen(SliderNames.login);
			return;
		}
		
		ExhibitorStoreHelper.openEditCartItem(this.storeItem.id);
	}
	
	removeFromCart() {
		if (!UserModule.user) {
			StoreConfigModule.setSliderOpen(SliderNames.login);
			return;
		}
		ExhibitorStoreHelper.removeFromCart(this.storeItem.id);
	}
	
	get displayImage() {
		return ExhibitorStoreModule.storeSettings.displayItemImages;
	}
	
	get fullImagePath() {
		return  this.displayImage ? this.useImage : "";
	}
	
	get unitNames() {
		return  this.storeItem.isTimeBound ? `${this.storeItem.unitName} per ${this.timeQuantityLabel}`:`${this.storeItem.unitName}`;
	}

	get timeQuantityLabel() {
		return this.storeItem.isTimeBound ? ExhibitorStoreHelper.getTimeQuantityLabel(this.storeItem.timeUnit, this.storeItem.timeQuantity) : "";
	}
}

</script>
<style lang="scss">
	.storeItem {
	  width: 240px;
	  height: 400px;
	 
	  .itemImage {
		height: 160px;
		border-bottom: 1px solid var(--v-grey-lighten2);
	  }
	  
	  .v-card__title {
		padding-top: 4px;
		padding-bottom: 4px ;
	  }

	  .itemTitle {
		font-size: 14px;
		font-weight: 600;
	  }
	  
	  .itemTitleText {
		max-width:175px;
		overflow: hidden;
		white-space: pre;
		text-overflow: ellipsis;
	  }
	
	  .itemTitleText:only-child {
		max-width:100%
	  }
	  
	  .itemPrice {
		font-size: 16px;
		color: black;
		margin-bottom: 2px;
	  }

	  .priceUnit {
		font-size: 12px;
		text-transform: capitalize;
	}

	  .inCartDisplay {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
	  }

	  .inCartDisplay .v-chip {
		background-color: var(--v-warning-lighten1);
		color: white;
		border-top: 1px solid var(--v-warning-lighten2);
		border-left: 1px solid var(--v-warning-lighten2);
		border-top-right-radius: 0px !important;
	 	border-bottom-left-radius: 0px !important;
		border-bottom-right-radius: 0px !important;
		text-transform: capitalize;
	  }

	  .itemDescription {
		font-size: 12px;
		font-weight: 400;
		height: 105px ;
		overflow: hidden;
	  }
	  
	 .v-card__subtitle, .itemDescription {
		padding-top: 0;
		padding-bottom: 0;
	  }
	 
	}
	
	.storeItem.noImage {
	  height: 270px;
	  
	  .inCartDisplay {
		height: 24px;
	  }
	  
	  .inCartDisplay .v-chip {
		border: 1px solid var(--v-warning-lighten2);
		border-radius: 5px !important;
	  }
	  
	  .v-card__text {
		padding-bottom:4px ;
		
	  }
  
	}
</style>