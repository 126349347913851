export interface MoneyFilterConfig {
    locale: string;
    maximumFractionDigits: number;
}

export interface MoneyFilterOptions {
    abbrev: boolean;
}

const defaultOptions: MoneyFilterOptions = {
    abbrev: false
};

let filterConfig: MoneyFilterConfig = {
    locale: "en-US",
    maximumFractionDigits: 2
};

export function configure(config: MoneyFilterConfig) {
    filterConfig = config;
}

export default function moneyFilter(value: number = 0, currency: string, options: MoneyFilterOptions = defaultOptions) {
    const abbrevOptions = !options.abbrev ? {} : {
        notation: 'compact',
        minimumFractionDigits: 0
    };

    const formatter = new Intl.NumberFormat(filterConfig.locale, {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: filterConfig.maximumFractionDigits === 0 ? 2 : filterConfig.maximumFractionDigits,
        ...abbrevOptions
    } as Intl.NumberFormatOptions);

    const result = formatter.format(value);
    return result;
}