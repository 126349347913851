import dateRangeFilter from "@/filters/dateRangeFilter";
import moneyFilter from "@/filters/moneyFilter";
import numberFilter from "@/filters/numberFilter";
import momentFilter from "@/filters/momentFilter";

export default {
    dateRangeFilter,
    moneyFilter,
    numberFilter,
    momentFilter
}