import {NavigationGuard, RouteConfig} from "vue-router";
import ExhibitorStoreCatagoryDisplay from "@/components/exhibitorStore/ExhibitorStoreCategoryDisplay.vue";
import ExhibitorStoreSearchDisplay from "@/components/exhibitorStore/ExhibitorStoreSearchDisplay.vue";
import ExhibitorStoreCartDisplay from "@/components/exhibitorStore/ExhibitorStoreCartDisplay.vue";

export const ExhibitorRoutes: RouteConfig[] = [
	{path:"", redirect:{name: "exhibitorStoreCategory"}},
	{name: "exhibitorStoreCategory", path: 'category/:categoryId?', component: ExhibitorStoreCatagoryDisplay},
	{name: "exhibitorStoreSearch", path: 'search', component: ExhibitorStoreSearchDisplay},
	{name: "exhibitorStoreCart", path: 'cart', component: ExhibitorStoreCartDisplay},
];