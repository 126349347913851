import axios, { AxiosRequestConfig } from 'axios';
import { CreatePaymentsPortalRequestModel } from '../../models/generated/createPaymentsPortalRequestModel';
import { SetOrderPaidFromPortalModel } from '../../models/generated/setOrderPaidFromPortalModel';

export const PaymentsPortalUrls = {
    createPaymentRequest: '/api/PaymentsPortal/createPaymentRequest',
    setPaid: '/api/PaymentsPortal/setPaid'
};

export class PaymentsPortalService {
    public static createPaymentRequest(model: CreatePaymentsPortalRequestModel, config: AxiosRequestConfig = {}): Promise<string> {
        config = {
            ...config,
        };
        const _data = model;
        return axios.post<string>(PaymentsPortalUrls.createPaymentRequest, _data, config).then(x => x.data);
    }
    
    public static setPaid(model: SetOrderPaidFromPortalModel, config: AxiosRequestConfig = {}): Promise<ArrayBuffer> {
        config = {
            responseType: 'arraybuffer',
            ...config,
        };
        const _data = model;
        return axios.put<ArrayBuffer>(PaymentsPortalUrls.setPaid, _data, config).then(x => x.data);
    }
    
}