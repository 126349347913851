<template>
	<store-page>
		<v-container v-if="isInitialized">
			<v-row justify="center">
				<v-col md="6">
					<v-card >
						<v-card-text>
							<h3 class="text-center">{{ $t($tl.exhibitionNotAvailable) }}</h3>
						</v-card-text>
					</v-card>
					<v-btn @click="goToExhibitionList" class="mt-5" text color="primary"><v-icon>arrow_back</v-icon>{{ $tc($tl.exhibitionList) }}</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</store-page>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import StorePage from "@/components/common/StorePage.vue";
import {ExhibitionWebModel, StoreConfigModel} from "@/models/generated";
import {ExhibitionsService, StoreConfigService} from "@/services/generated";
import StoreConfigModule from "@/store/storeConfigModule";
import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";

@Component({
	components: {StorePage}
})
export default class NoExhibitionsForEvent extends Vue {
	tenantId: string = null;
	exhibitions: ExhibitionWebModel[] = null;
	isInitialized: boolean = false;

	get storeConfig() {
		return StoreConfigModule.storeConfig;
	}

	goToExhibitionList() {
		this.$router.push({name:"exhibitions", params:{tenantId: this.tenantId }})
	}
	
	async created() {
		const {tenantId, venueId} = this.$route.params;
		this.tenantId = tenantId;

		this.isInitialized = true;
	}
}
</script>