<template>
	<vops-form @submit="submit()" v-if="isInitialized">
		<vops-slider-pane label="Forgot Password" @cancel="close()">
			<template #label>
					<v-icon  color="primary" x-large class="mr-2">mdi-account-question</v-icon> {{ $tc($tl.forgotPassword) }}
			</template>
				<v-list>
					<v-list-item>
						<v-list-item-title>
							{{$t($tl.reset.requestPasswordReset)}}
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-text-field v-model="email" :label="$t($tl.email)"
									  :rules="[$rules.required, $rules.email]" v-focus/>
					</v-list-item>
				</v-list>
				<div class="mb-4 text-center error--text" v-if="invalidEmail">
					{{$t($tl.reset.noUserFoundForThisEmail)}}
				</div>
			<template #footer>
				<div class="sliderPane-footer-content px-6 py-3">
					<v-btn class="sliderPane-footer-content" color="primary" type="submit" :loading="isSendingEmail" >
						{{$t($tl.sendEmail)}}
					</v-btn>
					<v-btn class="sliderPane-footer-content ml-4" @click="close">
						{{ $tc($tl.cancel) }}
					</v-btn>
				</div>
			</template>
		</vops-slider-pane>
	</vops-form>
</template>

<script lang="ts">
import {Component, Emit, Vue, Watch} from "vue-property-decorator";
import {UserService} from "@/services/generated";
import StoreConfigModule from "@/store/storeConfigModule";

@Component({
	components: {}
})
export default class UserForgotPasswordSlider extends Vue {
	isInitialized: boolean = true;
	isSendingEmail: boolean = false;
	email:string = null;
	invalidEmail:boolean = false;
	
	@Emit()
	close(){};
	
	@Emit()
	emailSent(){
		return this.email;
	}

	async submit() {
		if (this.isSendingEmail)
			return;

		try {
			this.isSendingEmail = true;

			UserService.requestPasswordReset({email: this.email});
			
			this.isSendingEmail = false;
			this.emailSent();
		} catch (e) {
			this.isSendingEmail = false;
			this.invalidEmail = true;
		}
	}
	
}
</script>