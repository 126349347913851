import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from "@/config/plugins/store";
import {CartUpdate, DepartmentInfo, StoreItem, StoreItemData, StoreSettings} from "@/services/exhibitorStoreHelper";
import ExhibitorStoreHelper, * as ESH from "@/services/exhibitorStoreHelper";
import StoreConfigModule, {SubscriberAction} from "@/store/storeConfigModule";
import {OnsiteContactModel} from "@/models/generated";
import {Order} from "@/store/exhibitorOrder";
const name: string = "ExhibitorStoreModule";

@Module({ dynamic: true, store: store, namespaced: true, name: name })

class ExhibitorStoreMod extends VuexModule {
	searchText: string = null;
	storeItems: ESH.StoreItem[] = [];
	departmentInfo: ESH.DepartmentInfo[] = [];
	totalPurchaseAmount: number = 0;
	cartItemCount: number = 0;
	currentItemId: string = null;
	onsiteContact: OnsiteContactModel = null;
	booth: string = null;
	lastCategoryId: string = null;
	sawStoreWideInstruction: boolean = false;
	
	storeSettings: StoreSettings = {
		tenantId: null,
		exhibitionId: null,
		currency:'USD',
		exhibitionInstruction: null,
		eventName: null,
		priceScheduleId: null,
		venueNames:[],
		startDate:null,
		endDate:null,
		isBoothRequired: false,
		userId: null,
		cartKey:null,
		displayItemImages: false
	}
	
	/*** Store Settings ***/
	@Action 
	setStoreSettings(storeSettings:StoreSettings) {
		this.onSetStoreSettings(storeSettings);	
	}

	@Action clearStoreSettings() {
		this.onSetStoreSettings({
			tenantId: null,
			exhibitionId: null,
			currency:'USD',
			exhibitionInstruction: null,
			eventName: null,
			priceScheduleId: null,
			venueNames:[],
			startDate:null,
			endDate:null,
			isBoothRequired: false,
			userId: null,
			cartKey: null,
			displayItemImages: false
		});
	}
	
	@Action 
	setSawStoreWideInstruction() {
		this.onSetSawStoreWideInstruction();
	}
	
	@Action 
	setDisplayImages(displayImages: boolean) {
		this.onSetDisplayImages(displayImages);
	}
	
	@Action
	setTenantId (tenantId:string) {
		this.onSetTenantId(tenantId);
	}

	@Action
	setExhibitionId (exhibitionId: string) {
		this.onSetExhibitionId(exhibitionId);
	}

	@Action
	setLastCategoryId (categoryId: string) {
		this.onSetLastCategoryId(categoryId);
	}

	@Action
	setPriceScheduleId (priceScheduleId: string) {
		this.onSetPriceScheduleId(priceScheduleId);
	}
	
	@Action
	setExhibitionEventName (eventName: string) {
		this.onSetExhibitionEventName(eventName);
	}

	@Action
	setExhibitionVenueNames (venueNames: string[]) {
		this.onSetExhibitionVenueNames(venueNames);
	}

	@Action
	setCurrency(currency:string) {
		this.onSetCurrency(currency);
	}

	@Action
	setStoreExhibitionInstruction(instruction:string) {
		this.onSetStoreExhibitionInstruction(instruction);
	}

	@Action
	setDepartmentInfo(departmentInfo:DepartmentInfo[]) {
		this.onSetDepartmentInfo(departmentInfo);
	}

	/*** Store Items Actions ***/

	@Action clearStoreItems() {
		this.onClearStoreItems();
	}

	@Action 
	setCurrentItemId (storeItemId:string) {
		this.onSetCurrentItemId(storeItemId);
	}

	@Action
	loadStoreItems(storeItems: ESH.StoreItem[]) {
		storeItems.forEach(storeItem => {
			this.onUpdateStoreItem(storeItem);
		})
	}
	
	@Action
	updateStoreItem(storeItemBase:ESH.StoreItemBase) {
		this.onUpdateStoreItem(storeItemBase);
	}
	
	/*** Cart Actions ***/
	@Action clearCart() {
		const cartItems = this.storeItems.filter(storeItem => storeItem.isInCart).map(storeItem => storeItem.id);
		cartItems.forEach(cartItemId => this.onRemoveFromCart(cartItemId));
	}

	@Action
	addToCart(storeItemId:string) {
		this.onAddToCart(storeItemId);
	}

	@Action
	removeFromCart(storeItemId:string) {
		this.onRemoveFromCart(storeItemId);
	}

	@Action
	setTotalPurchaseAmount(amount:number) {
		this.onSetTotalPurchaseAmount(amount);
	}

	@Action
	setCartItemTotal(amount:number) {
		this.onSetCartItemCount(amount);
	}

	@Action
	updateCartItem(storeItemData: ESH.StoreItemData) {
		this.onUpdateCartItem(storeItemData);
	}
	
	@Action
	restoreUserCartForExhibition(cartKey:string) {
		if(!this.storeSettings.cartKey)
			return;
		
		const json = window.localStorage.getItem(this.storeSettings.cartKey);
		if (json) {
			const cartItems = JSON.parse(json) as savedCartInfo[];
			cartItems.forEach(cartItem => this.onUpdateCartItem({
				id: cartItem.id,
				quantity: cartItem.q,
				timeQuantity: cartItem.t,
				instruction: cartItem.i,
				isInCart: true
			}))
		}
	}
	
	@Action
	saveUserCartForExhibition(cartKey:string) {
		if (!cartKey || !this.storeSettings.userId)
			return;
		
		const cartItems: savedCartInfo[] = this.storeItems.filter(storeItem => storeItem.isInCart).map( storeItem => {
			return {
				id: storeItem.id,
				q: storeItem.quantity,
				t: storeItem.timeQuantity,
				i: storeItem.instruction,
			}
		})
		
		if (cartItems.length > 0) {
			const json = JSON.stringify(cartItems);
			window.localStorage.setItem(cartKey, json);
		} else {
			window.localStorage.removeItem(cartKey)
		}
	}
	
	@Action 
	clearSavedUserCartForExhibition(cartKey:string) {
		window.localStorage.removeItem(cartKey);
	}
	
	/*** Search Actions ***/
	@Action 
	setSearchText(search:string) {
		this.onSetSearchText(search);
		StoreConfigModule.subscriberEvent(SubscriberAction.search_updated);
	}
	
	@Action
	clearSearchText() {
		this.onSetSearchText(null);
		StoreConfigModule.subscriberEvent(SubscriberAction.search_cleared);
	}
	
	/*** User Info Actions ***/
	
	@Action 
	setUserId(userId: string) {
		this.onSetUserId(userId);
		if (this.storeSettings.tenantId && this.storeSettings.exhibitionId) {
			this.onSetCartKey(`Cart-${this.storeSettings.tenantId}_${this.storeSettings.exhibitionId}_${userId}`)
		}
	}
	
	@Action
	setOnsiteContact(onsiteContact: OnsiteContactModel) {
		this.onSetOnsiteContact(onsiteContact);
	}

	@Action
	setBooth(booth: string) {
		this.onSetBooth(booth);
	}
	
	@Action
	setCategoryInstructionSeen(categoryid: string) {
		this.onSetCategoryInstructonSeen(categoryid);
	}

	/**** Mutations Below ****/
	@Mutation 
	onSetStoreSettings(storeSettings:StoreSettings) {
		this.storeSettings = {...storeSettings};
	}
	
	@Mutation
	onSetCurrentItemId(storeItemId:string) {
		this.currentItemId = storeItemId;
	}
	
	@Mutation
	onSetTenantId (tenantId: string) {
		this.storeSettings = {...this.storeSettings, tenantId: tenantId}
	}

	@Mutation
	onSetExhibitionId (exhibitionId: string) {
		this.storeSettings = {...this.storeSettings, exhibitionId: exhibitionId}
	}

	@Mutation
	onSetLastCategoryId (categoryId: string) {
		this.lastCategoryId = categoryId;
	}
	
	@Mutation
	onSetPriceScheduleId (priceScheduleId: string) {
		this.storeSettings = {...this.storeSettings, priceScheduleId: priceScheduleId}
	}
	
	@Mutation
	onSetCurrency(currency:string) {
		this.storeSettings = {...this.storeSettings, currency: currency}
	}

	@Mutation
	onSetStoreExhibitionInstruction(instructon:string) {
		this.storeSettings = {...this.storeSettings, exhibitionInstruction: instructon}
	}
	
	@Mutation 
	onSetExhibitionEventName(eventName:string) {
		this.storeSettings = {...this.storeSettings, eventName: eventName}
	}

	@Mutation
	onSetExhibitionVenueNames(venueNames:string[]) {
		this.storeSettings = {...this.storeSettings, venueNames:[...venueNames]}
	}

	@Mutation 
	onUpdateStoreItem(storeItem: ESH.StoreItemBase) {
		const index = this.storeItems.findIndex(i => i.id === storeItem.id)
		
		if (index >= 0) {
			const origItem = this.storeItems[index];
			this.storeItems[index] = {...origItem, ...storeItem}
		} else {
			this.storeItems.push({...storeItem, quantity:0, timeQuantity:0, instruction:"", isInCart:false });
		}
	}
	
	@Mutation 
	onAddToCart(storeItemId:string) {
		const index = this.storeItems.findIndex(i => i.id === storeItemId)

		if (index === -1)
			return;
		
		const origItem = this.storeItems[index];
		this.storeItems[index] = {...origItem, isInCart: true, quantity:1, timeQuantity: origItem.timeQuantity}
	}

	@Mutation
	onUpdateCartItem(storeItemData: ESH.StoreItemData) {
		const index = this.storeItems.findIndex(i => i.id === storeItemData.id)

		if (index === -1)
			return;

		const origItem = this.storeItems[index];
		this.storeItems[index] = {...origItem, isInCart: true, quantity: storeItemData.quantity, timeQuantity:storeItemData.timeQuantity, instruction: storeItemData.instruction}
	}
	
	@Mutation 
	onClearStoreItems() {
		this.storeItems = [];
	}
	
	@Mutation
	onRemoveFromCart(storeItemId:string) {
		const index = this.storeItems.findIndex(i => i.id === storeItemId)

		if (index === -1)
			return;

		const origItem = this.storeItems[index];
		this.storeItems[index] = {...origItem, isInCart: false, quantity:0, instruction:"", timeQuantity:origItem.defaultTimeQuantity}
	}
	
	@Mutation 
	onSetUserId(userId:string) {
		this.storeSettings.userId = userId;		
	}
	
	@Mutation 
	onSetDisplayImages(displayImages:boolean) {
		this.storeSettings.displayItemImages = displayImages;
	}
	
	@Mutation
	onSetCartKey(cartKey:string) {
		this.storeSettings.cartKey = cartKey;
	}

	@Mutation
	onSetDepartmentInfo(departmentInfo:DepartmentInfo[]) {
		this.departmentInfo = [...departmentInfo];
	}
	
	@Mutation 
	onSetTotalPurchaseAmount(amount:number) {
		this.totalPurchaseAmount = amount;
	}
	
	@Mutation
	onSetCartItemCount(amount:number) {
		this.cartItemCount = amount;
	}
	
	@Mutation
	onSetSearchText(search:string) {
		this.searchText = search;
	}
	
	@Mutation 
	onSetOnsiteContact(onsiteContact: OnsiteContactModel) {
		this.onsiteContact = {...onsiteContact}
	}

	@Mutation
	onSetBooth(booth: string) {
		this.booth = booth;
	}
	
	@Mutation
	onSetCategoryInstructonSeen(categoryId: string) {
		this.departmentInfo.forEach(dept => {
			const indx = dept.categories.findIndex(cat => cat.id === categoryId);
			if(indx >= 0) {
				dept.categories[indx] = {...dept.categories[indx], instructionSeen:true}
			}
		})
	}
	
	@Mutation 
	onSetSawStoreWideInstruction() {
		this.sawStoreWideInstruction = true;
	}
}

//To save local storage space the object names are shortened.
// q = quantity, t = time quantity, i = instructions 
interface savedCartInfo {
	id:string,
	q:number,
	t:number,
	i:string
}

const ExhibitorStoreModule = getModule(ExhibitorStoreMod);
export default ExhibitorStoreModule;