import axios, { AxiosRequestConfig } from 'axios';
import { OrderModel } from '../../models/generated/orderModel';

export const OrdersUrls = {
    getByCurrentUser: '/api/Orders/getByCurrentUser'
};

export class OrdersService {
    public static getByCurrentUser(config: AxiosRequestConfig = {}): Promise<OrderModel[]> {
        config = {
            ...config,
        };
        return axios.get<OrderModel[]>(OrdersUrls.getByCurrentUser, config).then(x => x.data);
    }
    
}