import moment from 'moment';

export default function momentFilter(value: string, format: string) {
	return moment(value, [
		"HH:mm:ss",
		"YYYY-MM-DD",
		"YYYY-MM-DDTHH",
		"YYYY-MM-DDTHH:mm:ss",
		moment.ISO_8601
	]).format(format);
}