<template>
	<exhibitor-store-item-display 
			show-category-instruction-on-items 
			:store-item-list="cartItems" 
			:is-loading="isLoading" 
			:no-items-msg="$tc($tl.noItemsInCart)" 
			:currency="currency"/>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import ExhibitorStoreItemDisplay from "@/components/exhibitorStore/common/ExhibitorStoreItemDisplay.vue";
import StoreConfigModule from "@/store/storeConfigModule";
import {ExhibitionService} from "@/services/generated";
import ExhibitorOrderModule from "@/store/exhibitorOrder";
import Enumerable from "linq";
import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";
import ExhibitorStoreModule from "@/store/exhibitorStoreModule";

@Component({
	components: {ExhibitorStoreItemDisplay}
})

export default class ExhibitorStoreCartDisplay  extends Vue {
	isInitialized: boolean = true;
	tenantId: string = null;
	exhibition = null ;
	exhibitionId: string = null;
	categoryId: string = null;
	currency: string = "USD";
	cartItems: string[] = []
	isLoading: boolean = false;

	get storeSettings() {
		return ExhibitorStoreModule.storeSettings;
	}

	async created() {
		ExhibitorStoreHelper.clearSearchText();
		this.currency = this.storeSettings.currency;
		await this.loadCartItems();
	}

	@Watch('ExhibitorStoreModule.lastUpdate')
	async loadCartItems() {
		this.isLoading = true;
			this.cartItems = ExhibitorStoreHelper.getStoreItemIdsInCart() ;
		this.isLoading = false;
	}
}

interface StoreItem {
	name: string
}
</script>