<template>
<div class="mainCartDisplay">
	<div class="instructions">
		<v-banner 
				v-if="storeExhibitionInstruction && storeExhibitionInstruction.length > 0"
				color="warning"
				class="text-center"
				sticky
				dark>
			{{displayStoreInstruction}}
			<v-chip v-if="storeInstructionHasLineBreaks" class="ml-3" small outlined text color="white" @click="isShowingInstructions=true">{{$tc($tl.more)}}</v-chip>
		</v-banner>
		<v-banner
				v-if="categoryInstruction && categoryInstruction.length > 0"
				dark
				sticky
				color="primary"
				class="text-center">
			{{displayCategoryInstruction}}
			<v-chip v-if="categoryInstructionHasLineBreaks && !storeInstructionHasLineBreaks" class="ml-3" outlined small text color="white"  @click="isShowingInstructions=true">{{$tc($tl.more)}}</v-chip>
		</v-banner>
	</div>
	<v-container fluid :class="{noItems:!hasItems}">
			<template v-if="hasItems">
				<v-row class="mt-6 ml-2">
					<exhibitor-store-item v-if="hasItems" v-for="item in storeItems" :show-category-warning="showCategoryInstructionOnItems" :currency="currency" :key="item.id" :store-item-id="item.id"/>
				</v-row>
			</template>
			<template v-else-if="isLoading">
				<v-row class="my-6 ml-2" >
					<v-col cols="12">
				<v-card class="noItemMsg">
					<v-card-title>{{ $tc($tl.loading) }}</v-card-title>
				</v-card>
					</v-col>
				</v-row>
			</template>
			<template v-else>
				<v-row class="my-6 ml-2">
					<v-col cols="12">
				<v-card class="noItemMsg">
					<v-card-title>{{noItemsMsg}}</v-card-title>
				</v-card>
					</v-col>
				</v-row>
			</template>
		<v-spacer/>
	</v-container>
	<v-snackbar centered v-model="isShowingInstructions" color="primary lighten2" outlined class="exhibitorInstructionsMessage" timeout="-1">
			<div class="message">
				<div><v-icon color="primary lighten1">mdi-information-outline</v-icon></div>	
				<div class="instructionLine">
					<div v-if="storeExhibitionInstruction">{{storeExhibitionInstruction}}</div>
					<v-divider v-if="storeExhibitionInstruction && categoryInstruction" />
					<div v-if="categoryInstruction">{{ categoryInstruction }}</div>
				</div>
			</div>
		    <div class="text-center">
				<v-btn small color="primary" @click="closeInstructions">{{$tc($tl.close)}}</v-btn>
			</div>
	</v-snackbar>
</div>
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ExhibitorStoreItem from "@/components/exhibitorStore/common/ExhibitorStoreItem.vue";
import ExhibitorStoreModule from "@/store/exhibitorStoreModule";
import ExhibitorStoreHelper, * as ESH from "@/services/exhibitorStoreHelper";

@Component({
	components: {ExhibitorStoreItem}
})

export default class ExhibitorStoreItemDisplay extends Vue {
	@Prop()
	storeItemList:string[];
	
	@Prop()
	currency;
	
	@Prop({default:false})
	isLoading;
	
	@Prop({default: "No Items were found"}) 
	noItemsMsg;
	
	@Prop({type:Boolean, default:false})
	showCategoryInstructionOnItems;

	@Prop({default:null})
	categoryInformation:ESH.CategoryInfo
	
	storeItems:ESH.StoreItem[] = [];
	
	storeExhibitionInstruction:string = null;
	categoryInstruction:string = null;
	categoryInfo: ESH.CategoryInfo = {
		id: null,
		exhibitorInstruction: null,
		name: null,
		instructionSeen : true
	}
	
	isShowingInstructions: boolean = false;
	
	get storeInstructionHasLineBreaks() {
		return this.storeExhibitionInstruction && this.storeExhibitionInstruction.search(/\n/) >= 0;
	}
	
	get categoryInstructionHasLineBreaks() {
		return this.categoryInstruction && this.categoryInstruction.search(/\n/) >= 0;
	}
	
	get displayStoreInstruction() {
		if (this.storeInstructionHasLineBreaks)
		{
			return this.storeExhibitionInstruction.split(/\n/)[0] ;
		}
		
		return this.storeExhibitionInstruction;
	}

	get displayCategoryInstruction() {
		if (this.categoryInstructionHasLineBreaks)
		{
			return this.categoryInstruction.split(/\n/)[0];
		}

		return this.categoryInstruction;
	}


	get storeSettings () {
		return ExhibitorStoreModule.storeSettings;
	}
	
	get hasItems () {
		return this.storeItemList.length > 0;
	}
	
	created() {
		this.storeExhibitionInstruction = this.storeSettings.exhibitionInstruction;
		this.loadStoreItems();
		this.checkInstructionDisplay();
	}

	checkInstructionDisplay() {
		this.isShowingInstructions =  (!this.categoryInfo.instructionSeen && this.categoryInstructionHasLineBreaks) || (!ExhibitorStoreModule.sawStoreWideInstruction && this.storeInstructionHasLineBreaks);
	}
	
	closeInstructions() {
		this.isShowingInstructions = false;
		if(this.categoryInstructionHasLineBreaks) {
			ExhibitorStoreHelper.categoryInstructionSeen(this.categoryInfo.id);
		}
		if(this.storeInstructionHasLineBreaks) {
			ExhibitorStoreHelper.sawStoreWideInstruction();
		}
	}
	
	@Watch("storeItemList")
	loadStoreItems() {
		if (this.categoryInformation)
			this.categoryInfo = {...this.categoryInformation};
		
		this.categoryInstruction = this.categoryInfo.exhibitorInstruction;
		this.storeItems = ExhibitorStoreHelper.getStoreItemsByList(this.storeItemList);
		this.checkInstructionDisplay();
	}
	
	isInitialized: boolean = true;
	
}

</script>
<style lang="scss">
	.mainCartDisplay {
	  min-height: 90%;

	 & .instructions {
		position: -webkit-sticky;
		position: sticky;
		top: 103px;
		z-index:7;
		margin-bottom:20px;
	  }

	  & .noItems {
		height:87vh;
		margin:0;
	  }

	   & .noItemMsg {
		margin: 20px 20px;
	  }
	  
	  & .v-banner > .v-banner__wrapper {
		padding-top: 8px;
		padding-bottom: 8px;
	  }
	}
	.mainAppBarMobileFixed + div  .mainCartDisplay .instructions {
	  top:156px;
	}
	
	.exhibitorInstructionsMessage.v-snack--active {
		background-color: rgba(0,0,0,.4);
	}
	
	.exhibitorInstructionsMessage {
	  .message {
		display: flex;
		flex-direction: row;
		color: black;
		margin-bottom: 10px;
		white-space: pre-wrap;
		
		.instructionLine {
		  margin-left: 10px;
		  .v-divider {
			margin-top:10px;
			margin-bottom: 10px;
		  }
		}
	  }
	}
</style>