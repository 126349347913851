<template>
	<vops-form @submit="submit()" v-if="isInitialized">
		<vops-slider-pane label="Login" save-label="LOGIN" @cancel="close()">
			<template #label>
				<v-icon color="primary" x-large class="mr-2">person_pin</v-icon> {{ $tc($tl.login) }}
			</template>
			<v-list class="mb-3">
				<v-list-item class="text-center">
					<v-list-item-title class="text-lg-h6 red--text" small>{{$tc($tl.loginNeeded)}}</v-list-item-title>
				</v-list-item>
				<v-list-item>
					<v-text-field v-model="email" :label="$t($tl.email)" :rules="[$rules.required, $rules.email]" v-focus/>
				</v-list-item>
				<v-list-item>
					<v-text-field v-model="password" :label="$t($tl.password)" type="password"
								  :rules="[$rules.required]"/>
				</v-list-item>
			</v-list>
			<div v-if="isAuthError" class="error--text">{{$t($tl.invalidLogin)}}</div>


			<template #footer>
				<div class="sliderPane-footer-content px-6 py-3">
					<v-btn class="sliderPane-footer-content" color="primary" type="submit" :loading="isLoggingIn" >
						{{ $t($tl.login) }}
					</v-btn>

					<v-btn class="sliderPane-footer-content ml-4" @click="forgotPassword">
						{{ $t($tl.forgotPassword) }}
					</v-btn>
					<v-spacer/>
					<v-btn class="sliderPane-footer-content ml-4" @click="close">
						{{ $tc($tl.cancel) }}
					</v-btn>
				</div>
				<div class="mt-4 text-center">
					<span>{{$t($tl.noAccount)}}</span>
				</div>
				<div class="text-center">
					<v-btn text color="primary" @click="openRegistration">{{$t($tl.signUp)}}</v-btn>
				</div>
			</template>
		</vops-slider-pane>
	</vops-form>
</template>


<script lang="ts">
import {Component, Prop, Emit, Vue} from "vue-property-decorator";
import StoreConfigModule, {SliderNames} from "@/store/storeConfigModule";
import {LoginModel} from "@/models/generated";
import {AuthService} from "@/services/generated";
import UserModule from "@/store/userModule";
import ExhibitorStoreHelper from "@/services/exhibitorStoreHelper";

@Component({
	components: {}
})

export default class UserLoginSlider extends Vue {
	email: string = null;
	password: string = null;
	isLoggingIn: boolean = false;
	isAuthError: boolean = false;
	isInitialized: boolean = false;

	@Prop({type:String, default:null})
	loginEmail: string;
	
	@Emit() userLoggedIn(){
		return this.email;
	};
	
	@Emit() close(){};
	
	async created() {
		this.email = this.loginEmail;
		await StoreConfigModule.clearConfig();
		this.isInitialized = true;
	}

	openRegistration() {
		StoreConfigModule.setSliderClosed(SliderNames.login);
		StoreConfigModule.setSliderOpen(SliderNames.registerNewAccount);
	}
	
	
	forgotPassword() {
		StoreConfigModule.setSliderClosed(SliderNames.login);
		StoreConfigModule.setSliderOpen(SliderNames.forgotPassword);
	}
	
	async submit() {
		if (this.isLoggingIn)
			return;

		try {
			this.isLoggingIn = true;
			this.isAuthError = false;

			const model: LoginModel = {
				email: this.email,
				password: this.password
			};
			await AuthService.login(model);
			await UserModule.refreshUser();
			this.isLoggingIn = false;
			ExhibitorStoreHelper.userLoggedIn(UserModule.user.id);
			
			this.userLoggedIn();
		} catch (e) {
			this.isLoggingIn = false;
			this.isAuthError = true;
		}
	}
}

</script>
<style lang="scss">
	.loginText {
	  color:red;
	}
</style>